<template>
  <div
    id="importanceAnalysis"
    v-loading="loading"
    :element-loading-text="
      downloadRole
        ? this.$t(`message.loading.downloading`)
        : loading
        ? this.$t(`message.loading.suanfa`)
        : downloadRole
        ? this.$t(`message.loading.downloadcompletes`)
        : this.$t(`message.loading.Successsuanfa`)
    "
    style="width: 100%">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <Header :info="info"></Header>
      <!-- 选择项目\选择产品 -->
      <div class="header_bar">
        <!-- 第一行 -->
        <div class="bar_One">
          <div class="Page_title">
            {{ $t(`message.Category.page4.title`) }}
            <div class="line"></div>
          </div>

          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t(`message.Category.common.Category`)" class="bar_One_text_0">
              <el-select v-model="formInline0.product" @change="changeName" :placeholder="$t(`message.Category.page4.SelectOne`)">
                <el-option v-for="(item, index) in program_name" :key="index" :label="item" :value="index"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="$t(`message.Category.page4.Axis`)" class="bar_One_text_1">
              <el-select v-model="formInline.product" @change="changeCoordinate" :placeholder="$t(`message.Category.page4.Selects`)">
                <el-option v-for="(item, index) in coordinate" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 热力图和网络图切换 -->
          <!-- <div class="chart">
          <img src="https://newstore.vynior.com/ctmwangluotu.png" v-show="chartInfo==0" alt="">
          <img src="https://newstore.vynior.com/ctmrelitu.png" v-show="chartInfo==1" alt="">
        </div> -->
          <!-- 中文显示 -->
          <div class="button" v-show="$store.state.lang == 'cn'">
            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/download.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>
            <div class="port" @click="ActionAnalyse">
              <img src="https://newstore.vynior.com/Group%2033282%402x.png" alt="" />
            </div>
          </div>
          <!-- 英文显示 -->
          <div class="button" v-show="$store.state.lang == 'en'">
            <div class="compare">
              <img id="downloadImg" @click="downloadShow = !downloadShow" src="https://newstore.vynior.com/xzxz.png" alt="" />
              <div class="download-select" v-show="downloadShow">
                <div class="select-item" @click="downloadIMG()">{{ $t(`message.Category.common.DownloadGraph`) }}</div>
                <div class="select-item" @click="downloadIMGALL()">{{ $t(`message.Category.common.DownloadAll`) }}</div>
              </div>
            </div>
            <div class="port">
              <img src="https://newstore.vynior.com/fenxifx.png" alt="" @click="ActionAnalyse" />
            </div>
          </div>
        </div>
      </div>

      <!-- 下面的内容box -->
      <div class="Content_box">
        <!-- 空状态 -->
        <div class="none" v-show="show == 0">
          <img src="https://newstore.vynior.com/Group%203%402x.png" alt="" />
          <p>{{ $t(`message.Category.common.empty`) }}</p>
        </div>
        <div class="box-left">
          <!-- HINT 最新描述 -->
          <div class="box-left-top" v-show="indexing == 1">
            <div class="left-top-top">
              <div class="top-top-text">
                <span>{{ $t(`message.Category.page4.Attributes`) }}</span>
              </div>
              <img
                class="top-top-reset"
                src="https://newstore.vynior.com/importanceAnalysis-top-cz.png"
                @click="ffChange"
                alt=""
                v-show="$store.state.lang == 'cn'" />
              <img class="top-top-reset" src="https://newstore.vynior.com/resets.png" @click="ffChange" alt="" v-show="$store.state.lang == 'en'" />
            </div>
            <div class="left-top-cent">
              <el-select
                class="top-cent-select"
                v-model="ffindex"
                :placeholder="$t(`message.Category.page4.Select`)"
                v-show="$store.state.lang == 'cn'">
                <el-option v-for="(item, index) in ff" :key="index" :label="item" :value="index" :disabled="imgInfo || showTables == 0"></el-option>

                <!-- <el-option label="区域二" value="beijing"></el-option> -->
              </el-select>

              <el-select
                class="top-cent-select"
                v-model="ffindex"
                :placeholder="$t(`message.Category.page4.Select`)"
                v-show="$store.state.lang == 'en'">
                <el-option v-for="(item, index) in ffEn" :key="index" :label="item" :value="index" :disabled="imgInfo || showTables == 0"></el-option>

                <!-- <el-option label="区域二" value="beijing"></el-option> -->
              </el-select>
            </div>
            <div class="left-top-center">
              <span>{{ $t(`message.Category.page4.ChartType`) }}</span>
            </div>
            <div class="left-top-bot">
              <div class="top-bot-feat" :class="imgInfo ? 'top-bot-feats' : ''" @click="imgChange(true)">
                <span>{{ $t(`message.Category.page4.HeatMap`) }}</span>
              </div>
              <div class="top-bot-network" :class="!imgInfo ? 'top-bot-feats' : ''" @click="imgChange(false)">
                <span>{{ $t(`message.Category.page4.Network`) }}</span>
              </div>
            </div>
          </div>
          <!--  -->
          <!--  -->
          <!-- 图表描述 显示的状态 (无表格状态) -->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'cn'" :class="indexing == 1 ? 'tipss' : ''">
            <img
              :src="imgInfo ? 'https://newstore.vynior.com/Group%2033727%20%281%29.png' : 'https://newstore.vynior.com/rlttt.png'"
              alt=""
              :class="imgInfo == false ? 'imgWidth' : ''" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>
          <!-- 图表描述 显示的状态 (无表格状态)english -->
          <div class="tip" v-show="showChart == 1 && $store.state.lang == 'en'" :class="indexing == 1 ? 'tipss' : ''">
            <img
              :src="imgInfo ? 'https://newstore.vynior.com/Group%2033727%402xss.png' : 'https://newstore.vynior.com/Group%2033825%402x.png'"
              alt=""
              :class="imgInfo == false ? 'imgWidth' : ''" />
            <div class="text2" @click="showChart = 0">{{ $t(`message.Category.common.Hide`) }}</div>
          </div>

          <!-- 图表描述 隐藏的状态 (无表格状态) -->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'cn'" :class="indexing == 1 ? 'tipss' : ''">
            <img src="https://newstore.vynior.com/Group%2033821.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
          <!-- 图表描述 隐藏的状态 (无表格状态) english-->
          <div class="tips" v-show="showChart == 0 && $store.state.lang == 'en'" :class="indexing == 1 ? 'tipss' : ''">
            <img src="https://newstore.vynior.com/emptymiaos.png" alt="" />
            <div class="text2" @click="showChart = 1">{{ $t(`message.Category.common.Show`) }}</div>
          </div>
        </div>

        <!-- 图表描述 隐藏的状态 (有表格状态) -->
        <!-- <div class="tipss" v-show="showTables == 0 && show == 1">
        <div class="text">
          <img src="https://newstore.vynior.com/Bold%20%EF%BC%8F%20Notes%20%EF%BC%8F%20Document%20Text%402x.png"
            alt="" />
          <div class="text1">图表描述</div>
          <div class="text2" @mouseenter="showShadow = 1" @mouseleave="showShadow = 0">显示</div>
        </div>
        <div class="text3" v-show="showShadow == 1">
          <p>重要度分析图：小方块的颜色越深，则表示横纵两个属性之间的关联度越大。颜色越浅，横纵两个属性之间的关联度越小。</p>
        </div>
      </div> -->

        <!-- 分析后的数据 -->
        <div :class="showTables == 0 ? 'Product_show' : 'Product_showss'" v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1)">
          <!-- <div class="el-img"  @mouseover="urlIndexChange(1)" @mouseout="urlIndexChange(0)" >
          <div class="el-img2">
            <span>大图预览</span>
           <img class="el-img-search"
              :src="urlIndex==0?'https://newstore.vynior.com/search2.png':'https://newstore.vynior.com/search%20%281%29.png'" alt="">
          
          </div>
          <el-image 
            src="https://newstore.vynior.com/bottom-right.png" 
            :preview-src-list="srcList">
          </el-image>
        </div> -->
          <!-- 英文显示 -->
          <div class="cnbox" v-show="this.$store.state.lang === 'cn'">
            <img
              class="shows_o"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 0"
              :src="IMGlist[0]"
              alt="" />
            <img
              class="shows_t"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 1"
              :src="IMGlist[1]"
              alt="" />
            <img
              class="shows_h"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 2"
              :src="IMGlist[2]"
              alt="" />
          </div>
          <!-- 中文显示 -->
          <div class="enbox" v-show="this.$store.state.lang === 'en'">
            <img
              class="shows_o"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 0"
              :src="IMGlist[3]"
              alt="" />
            <img
              class="shows_t"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 1"
              :src="IMGlist[4]"
              alt="" />
            <img
              class="shows_h"
              v-show="show == 1 && ((indexing == 1 && imgInfo == true) || indexing != 1) && indexing == 2"
              :src="IMGlist[5]"
              alt="" />
          </div>
          <!-- !!!!数据 -->
          <div class="Product_shows" v-if="showTables == 1">
            <div class="Product_shows-cent" :class="indexing == 1 ? 'Product_shows-cents' : indexing == 2 ? 'Product_shows-centss' : ''">
              <!-- 数据一  观感属性 -->
              <div
                class="Product_shows-table"
                v-if="indexing == 0"
                @mouseout="outChange"
                :class="indexing == 0 ? 'table-one' : indexing == 1 ? 'table-two' : 'table-three'">
                <div class="Product_shows-table-column" v-for="(it, ind) in data2" :key="ind">
                  <!-- {{ it }} -->
                  <div
                    :class="num == ind * 29 + indexs ? 'table-column-xs' : 'table-column-x'"
                    v-for="(item, index, indexs) in it"
                    :key="indexs"
                    :style="{
                      background:
                        num == ind * 29 + indexs
                          ? 'rgb(' + rgbarr[indexing][num][0] + ',' + rgbarr[indexing][num][1] + ',' + rgbarr[indexing][num][2] + ')'
                          : '',
                    }">
                    <div @mouseover="terChange(ind, indexs)">
                      <el-tooltip :open-delay="280" class="item" effect="dark" :visible-arrow="false" :content="'' + item" placement="top">
                        <div slot="content">
                          {{ $store.state.lang === 'en' ? enheaderData2_y[ind] : chheaderData2_y[ind] }}/{{
                            $store.state.lang === 'en' ? enheaderData2_x[indexs] : chheaderData2_x[indexs]
                          }}<br />{{ item }}
                        </div>
                        <!-- <div slot="content">{{ data2[ind].num0 }}/{{headerData1[1]}}<br/>{{ it.num1 }}</div> -->
                        <el-button></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 数据二   感官属性/消费者标准-->
              <div
                class="Product_shows-table"
                v-if="indexing == 1"
                @mouseout="outChange"
                :class="indexing == 0 ? 'table-one' : indexing == 1 ? 'table-two' : 'table-three'">
                <div class="Product_shows-table-column" v-for="(it, ind) in data1" :key="ind">
                  <!-- {{ it }} -->
                  <div
                    :class="num == ind * 29 + indexs ? 'table-column-xs' : 'table-column-x'"
                    v-for="(item, index, indexs) in it"
                    :key="indexs"
                    :style="{
                      background:
                        num == ind * 29 + indexs
                          ? 'rgb(' + rgbarr[indexing][num][0] + ',' + rgbarr[indexing][num][1] + ',' + rgbarr[indexing][num][2] + ')'
                          : '',
                    }">
                    <div @mouseover="terChange(ind, indexs)">
                      <el-tooltip :open-delay="280" class="item" effect="dark" :visible-arrow="false" :content="'' + item" placement="top">
                        <div slot="content">
                          {{ $store.state.lang === 'en' ? enheaderData1_y[ind] : chheaderData1_y[ind] }}/{{
                            $store.state.lang === 'en' ? enheaderData1_x[indexs] : chheaderData1_x[indexs]
                          }}<br />{{ item }}
                        </div>
                        <!-- <div slot="content">{{ data2[ind].num0 }}/{{headerData1[1]}}<br/>{{ it.num1 }}</div> -->
                        <el-button></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 数据三  消费者指标-->
              <div
                class="Product_shows-table"
                v-if="indexing == 2"
                @mouseout="outChange"
                :class="indexing == 0 ? 'table-one' : indexing == 1 ? 'table-two' : 'table-three'">
                <div class="Product_shows-table-column" v-for="(it, ind) in data3" :key="ind">
                  <!-- {{ it }} -->
                  <div
                    :class="num == ind * 19 + indexs ? 'table-column-xs' : 'table-column-x'"
                    v-for="(item, index, indexs) in it"
                    :key="indexs"
                    :style="{
                      background:
                        num == ind * 19 + indexs
                          ? 'rgb(' + rgbarr[indexing][num][0] + ',' + rgbarr[indexing][num][1] + ',' + rgbarr[indexing][num][2] + ')'
                          : '',
                    }">
                    <div @mouseover="terChange(ind, indexs)">
                      <el-tooltip :open-delay="280" class="item" effect="dark" :visible-arrow="false" :content="'' + item" placement="top">
                        <div slot="content">
                          {{ $store.state.lang === 'en' ? enheaderData3_y[ind] : chheaderData3_y[ind] }}/{{
                            $store.state.lang === 'en' ? enheaderData3_x[indexs] : chheaderData3_x[indexs]
                          }}<br />{{ item }}
                        </div>
                        <!-- <div slot="content">{{ data2[ind].num0 }}/{{headerData1[1]}}<br/>{{ it.num1 }}</div> -->
                        <el-button></el-button>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--!!!! 数据结束  -->
          <!-- <div class=""></div> -->
        </div>

        <!-- 隐藏表格 -->
        <div class="showTable" v-show="show == 1 && showTables == 0">
          <img
            @mouseenter="showIMG = 1"
            @mouseleave="showIMG = 0"
            v-show="showIMG == 0"
            src="https://newstore.vynior.com/Group%2033716%402x.png"
            alt="" />
          <img
            @mouseleave="showIMG = 0"
            v-show="showIMG == 1"
            @click="showTables = 1"
            src="https://newstore.vynior.com/Group%2033718%402x.png"
            alt="" />
        </div>

        <!-- 显示表格 -->
        <!-- <div class="showTables" v-show="show == 1 && showTables == 1">
        <img @mouseenter="showIMG = 1" mouseleave="showIMG=0" v-show="showIMG == 0"
          src="https://newstore.vynior.com/Group%2033719%402x.png" alt="" />
        <img @mouseleave="showIMG = 0" v-show="showIMG == 1" @click="showTables = 0"
          src="https://newstore.vynior.com/Group%2033720%402x.png" alt="" />
      </div> -->

        <!-- 悬浮 -->

        <!-- 网络图 -->
        <div class="product_network" v-show="indexing == 1 && show == 1 && imgInfo == false">
          <img :src="$store.state.lang === 'en' ? ennetworkImgUrl[ffindex] : chnetworkImgUrl[ffindex]" alt="" />
        </div>

        <!-- <el-table :data="sheetData" height="831px" v-show="show == 1 && showTables == 0" class="sheet">
        <el-table-column :fixed="index == 0 ? true : false" :prop="`num${index}`" :label="item"
          v-for="(item, index) of sheetHeader" :key="index" width="160" :align="index == 0 ? 'left' : 'center'"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="content" :style="{
              color: index == 0 ? '#606266' : '#181a1d',
            }">
              {{
  index == 0
  ? scope.row[`num${index}`]
  : scope.row[`num${index}`].toFixed(2)
              }}
            </div>
          </template>
        </el-table-column>
      </el-table> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import { HttpClient } from '@/utils/http';
import getFilename from '../../utils/getFilename';
let time;
export default {
  name: 'importanceAnalysis',
  components: {
    Header,
  },
  data() {
    return {
      urlIndex: 0, //大图预览是否悬浮
      srcList: [], //大图预览功能所展示的图片
      downloadShow: false,
      downloadLoading: false,
      networkImgUrl: [], //网络图地址
      imgInfo: true, //true:热力图，false:网络图
      ffindex: '', //选中的坐标
      ff: [
        '整体喜好度',
        '购买意愿 INTENSITY',
        '整体使用体验 HEDONIC',
        '产品外观 HEDONIC',
        '产品颜色 HEDONIC',
        '产品质地 HEDONIC',
        '使用后的肤感 HEDONIC',
        '滋润感 HEDONICC',
        '水润感 HEDONIC',
        '粘腻度 HEDONIC',
        '油感 HEDONIC',
        '增白效果 HEDONIC',
        '顺滑度/光滑度 HEDONIC',
        '提亮效果/光泽度 HEDONIC',
        '清凉感 HEDONIC',
        '吸收速度 HEDONIC',
        '残留量 HEDONIC',
        '适合敏感肌 HEDONIC',
        '适合我的肤质 HEDONIC',
      ], //坐标
      ffEn: [
        'Overall liking',
        'Purchase intenison INTENSITY',
        'Overall usage experience HEDONIC',
        'Appearance HEDONIC',
        'Color HEDONIC',
        'Texture HEDONIC',
        'Skin feel after use HEDONIC',
        'Moisturizing HEDONIC',
        'Hydrating HEDONIC',
        'Stickness HEDONIC',
        'Oiliness HEDONIC',
        'Whitening HEDONIC',
        'Smoothness HEDONIC',
        'Brightening/radiance HEDONIC',
        'Cooling HEDONIC',
        'Absorption speed HEDONIC',
        'Residue HEDONIC',
        'Suitable for senstive skin HEDONIC',
        'Suitable for my skin HEDONIC',
      ],
      networkInfo: false, //网络图的显示隐藏
      //! 数据一  感官属性/消费者数据
      enheaderData1_x: [
        'Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Speed of absorption',
      ],
      enheaderData1_y: [
        'Overall liking',
        'Purchase intenison INTENSITY',
        'Overall usage experience HEDONIC',
        'Appearance HEDONIC',
        'Color HEDONIC',
        'Texture HEDONIC',
        'Skin feel after use HEDONIC',

        'Moisturizing HEDONIC',
        'Hydrating HEDONIC',
        'Stickness HEDONIC',
        'Oiliness HEDONIC',
        'Whitening HEDONIC',
        'Smoothness HEDONIC',
        'Brightening/radiance HEDONIC',
        'Cooling HEDONIC',
        'Absorption speed HEDONIC',
        'Residue HEDONIC',
        'Suitable for senstive skin HEDONIC',
        'Suitable for my skin HEDONIC',
      ],
      chheaderData1_x: [
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '总体吸收速度',
      ],
      chheaderData1_y: [
        '整体喜好度',
        '购买意愿 INTENSITY',
        '整体使用体验 HEDONIC',
        '产品外观 HEDONIC',
        '产品颜色 HEDONIC',
        '产品质地 HEDONIC',
        '使用后的肤感 HEDONIC',
        '滋润感 HEDONICC',
        '水润感 HEDONIC',
        '粘腻度 HEDONIC',
        '油感 HEDONIC',
        '增白效果 HEDONIC',
        '顺滑度/光滑度 HEDONIC',
        '提亮效果/光泽度 HEDONIC',
        '清凉感 HEDONIC',
        '吸收速度 HEDONIC',
        '残留量 HEDONIC',
        '适合敏感肌 HEDONIC',
        '适合我的肤质 HEDONIC',
      ],
      data1: [
        {
          num1: 0.32,
          num2: 0.5,
          num3: 0.12,
          num4: -0.44,
          num5: -0.31,
          num6: -0.58,
          num7: -0.4,
          num8: -0.25,
          num9: 0.15,
          num10: -0.78,
          num11: 0.14,
          num12: -0.32,
          num13: 0.66,
          num14: 0.82,
          num15: -0.46,
          num16: 0.93,
          num17: -0.25,
          num18: -0.66,
          num19: 0.34,
          num20: -0.57,
          num21: -0.32,
          num22: -0.55,
          num23: 0.18,
          num24: -0.58,
          num25: -0.37,
          num26: -0.26,
          num27: -0.02,
          num28: -0.62,
          num29: 0.55,
        },
        // 1
        {
          num1: 0.4,
          num2: 0.46,
          num3: 0.27,
          num4: -0.27,
          num5: 0.03,
          num6: -0.33,
          num7: -0.53,
          num8: 0.13,
          num9: 0.14,
          num10: -0.37,
          num11: 0.04,
          num12: 0.01,
          num13: 0.75,
          num14: 0.39,
          num15: -0.25,
          num16: 0.34,
          num17: -0.27,
          num18: -0.14,
          num19: -0.08,
          num20: -0.59,
          num21: -0.42,
          num22: -0.26,
          num23: -0.19,
          num24: -0.67,
          num25: -0.56,
          num26: -0.24,
          num27: -0.27,
          num28: -0.77,
          num29: 0.42,
        },
        // 2
        {
          num1: 0.31,
          num2: 0.44,
          num3: 0.24,
          num4: -0.32,
          num5: -0.17,
          num6: -0.47,
          num7: -0.38,
          num8: -0.13,
          num9: 0.11,
          num10: -0.66,
          num11: 0.04,
          num12: -0.36,
          num13: 0.65,
          num14: 0.74,
          num15: -0.46,
          num16: 0.81,
          num17: -0.28,
          num18: -0.52,
          num19: 0.24,
          num20: -0.59,
          num21: -0.35,
          num22: -0.39,
          num23: 0.08,
          num24: -0.63,
          num25: -0.38,
          num26: -0.11,
          num27: -0.08,
          num28: -0.65,
          num29: 0.58,
        },

        // 3
        {
          num1: 0.47,
          num2: 0.58,
          num3: 0.13,
          num4: -0.39,
          num5: -0.08,
          num6: -0.32,
          num7: -0.55,
          num8: -0.01,
          num9: 0.09,
          num10: -0.41,
          num11: 0.02,
          num12: -0.34,
          num13: 0.46,
          num14: 0.55,
          num15: -0.19,
          num16: 0.62,
          num17: -0.06,
          num18: -0.44,
          num19: 0.27,
          num20: -0.43,
          num21: -0.11,
          num22: -0.4,
          num23: 0.11,
          num24: -0.47,
          num25: -0.17,
          num26: -0.25,
          num27: -0.04,
          num28: -0.48,
          num29: 0.33,
        },
        // 4
        {
          num1: 0.52,
          num2: 0.65,
          num3: 0.18,
          num4: -0.59,
          num5: -0.63,
          num6: -0.73,
          num7: -0.42,
          num8: -0.48,
          num9: 0.15,
          num10: -0.9,
          num11: 0.38,
          num12: -0.43,
          num13: 0.63,
          num14: 0.78,
          num15: -0.42,
          num16: 0.82,
          num17: -0.19,
          num18: -0.69,
          num19: 0.57,
          num20: -0.52,
          num21: -0.3,
          num22: -0.58,
          num23: 0.37,
          num24: -0.6,
          num25: -0.36,
          num26: -0.3,
          num27: 0.16,
          num28: -0.62,
          num29: 0.51,
        },
        // 5
        {
          num1: 0.53,
          num2: 0.69,
          num3: 0.14,
          num4: -0.46,
          num5: -0.23,
          num6: -0.56,
          num7: -0.45,
          num8: -0.12,
          num9: 0.26,
          num10: -0.77,
          num11: 0.08,
          num12: -0.36,
          num13: 0.63,
          num14: 0.75,
          num15: -0.24,
          num16: 0.91,
          num17: -0.09,
          num18: -0.63,
          num19: 0.51,
          num20: -0.43,
          num21: -0.16,
          num22: -0.54,
          num23: 0.35,
          num24: -0.46,
          num25: -0.2,
          num26: -0.28,
          num27: 0.14,
          num28: -0.47,
          num29: 0.43,
        },
        // 6
        {
          num1: 0.29,
          num2: 0.46,
          num3: 0.14,
          num4: -0.35,
          num5: -0.19,
          num6: -0.47,
          num7: -0.38,
          num8: -0.18,
          num9: 0.11,
          num10: -0.63,
          num11: 0.02,
          num12: -0.33,
          num13: 0.58,
          num14: 0.74,
          num15: -0.41,
          num16: 0.8,
          num17: -0.24,
          num18: -0.53,
          num19: 0.22,
          num20: -0.55,
          num21: -0.3,
          num22: -0.41,
          num23: 0.07,
          num24: -0.56,
          num25: -0.33,
          num26: -0.14,
          num27: -0.11,
          num28: -0.6,
          num29: 0.56,
        },
        // 7
        {
          num1: 0.52,
          num2: 0.61,
          num3: 0.31,
          num4: -0.36,
          num5: 0.06,
          num6: -0.33,
          num7: -0.57,
          num8: 0.06,
          num9: 0.01,
          num10: -0.54,
          num11: -0.47,
          num12: -0.44,
          num13: 0.49,
          num14: 0.71,
          num15: -0.26,
          num16: 0.79,
          num17: -0.22,
          num18: -0.38,
          num19: 0.09,
          num20: -0.67,
          num21: -0.22,
          num22: -0.24,
          num23: -0.06,
          num24: -0.6,
          num25: -0.24,
          num26: 0.06,
          num27: -0.23,
          num28: -0.57,
          num29: 0.62,
        },
        // 8
        // 123123123
        {
          num1: 0.41,
          num2: 0.52,
          num3: 0.32,
          num4: -0.19,
          num5: 0.18,
          num6: -0.22,
          num7: -0.41,
          num8: 0.21,
          num9: -0.09,
          num10: -0.46,
          num11: -0.5,
          num12: -0.49,
          num13: 0.34,
          num14: 0.62,
          num15: -0.21,
          num16: 0.75,
          num17: -0.19,
          num18: -0.41,
          num19: -0.05,
          num20: -0.66,
          num21: -0.18,
          num22: -0.29,
          num23: -0.28,
          num24: -0.63,
          num25: -0.22,
          num26: 0.04,
          num27: -0.39,
          num28: -0.62,
          num29: 0.67,
        },
        // 黏腻
        {
          num1: 0.21,
          num2: 0.3,
          num3: 0.01,
          num4: -0.33,
          num5: -0.24,
          num6: -0.37,
          num7: -0.3,
          num8: -0.23,
          num9: 0.07,
          num10: -0.44,
          num11: 0.02,
          num12: -0.18,
          num13: 0.43,
          num14: 0.61,
          num15: -0.37,
          num16: 0.62,
          num17: -0.32,
          num18: -0.33,
          num19: -0.01,
          num20: -0.53,
          num21: -0.34,
          num22: -0.26,
          num23: -0.08,
          num24: -0.53,
          num25: -0.38,
          num26: -0.08,
          num27: -0.19,
          num28: -0.56,
          num29: 0.55,
        },
        //油
        {
          num1: 0.08,
          num2: 0.15,
          num3: 0.13,
          num4: -0.16,
          num5: -0.16,
          num6: -0.31,
          num7: -0.13,
          num8: -0.06,
          num9: 0.17,
          num10: -0.48,
          num11: 0.06,
          num12: -0.25,
          num13: 0.35,
          num14: 0.57,
          num15: -0.58,
          num16: 0.76,
          num17: -0.43,
          num18: -0.63,
          num19: 0.16,
          num20: -0.64,
          num21: -0.44,
          num22: -0.54,
          num23: 0.01,
          num24: -0.62,
          num25: -0.47,
          num26: -0.22,
          num27: -0.2,
          num28: -0.68,
          num29: 0.6,
        },
        // 增白效果
        {
          num1: 0.15,
          num2: 0.2,
          num3: -0.22,
          num4: -0.28,
          num5: -0.12,
          num6: -0.19,
          num7: -0.33,
          num8: -0.1,
          num9: -0.09,
          num10: -0.22,
          num11: 0.21,
          num12: 0.1,
          num13: 0.34,
          num14: 0.25,
          num15: 0.06,
          num16: 0.32,
          num17: 0.13,
          num18: -0.18,
          num19: 0.18,
          num20: -0.05,
          num21: 0.09,
          num22: -0.25,
          num23: 0.16,
          num24: -0.07,
          num25: 0.02,
          num26: -0.27,
          num27: 0.16,
          num28: -0.12,
          num29: 0.01,
        },
        // 滑度============
        {
          num1: 0.38,
          num2: 0.4,
          num3: 0.36,
          num4: -0.3,
          num5: -0.05,
          num6: -0.29,
          num7: -0.52,
          num8: -0.04,
          num9: -0.15,
          num10: -0.5,
          num11: -0.42,
          num12: -0.45,
          num13: 0.62,
          num14: 0.65,
          num15: -0.33,
          num16: 0.65,
          num17: -0.3,
          num18: -0.14,
          num19: -0.18,
          num20: -0.69,
          num21: -0.32,
          num22: -0.03,
          num23: -0.29,
          num24: -0.67,
          num25: -0.36,
          num26: 0.2,
          num27: -0.36,
          num28: -0.66,
          num29: 0.69,
        },
        // 光泽度
        {
          num1: 0.13,
          num2: 0.19,
          num3: -0.21,
          num4: -0.18,
          num5: -0.02,
          num6: -0.11,
          num7: -0.26,
          num8: -0.01,
          num9: -0.05,
          num10: -0.14,
          num11: 0.19,
          num12: 0.09,
          num13: 0.27,
          num14: 0.17,
          num15: 0.12,
          num16: 0.29,
          num17: 0.17,
          num18: -0.19,
          num19: 0.25,
          num20: 0.05,
          num21: 0.12,
          num22: -0.25,
          num23: 0.23,
          num24: 0.02,
          num25: 0.08,
          num26: -0.26,
          num27: 0.21,
          num28: -0.03,
          num29: -0.07,
        },
        // 清凉感
        {
          num1: 0.3,
          num2: 0.46,
          num3: 0.05,
          num4: -0.34,
          num5: -0.03,
          num6: -0.32,
          num7: -0.46,
          num8: -0.02,
          num9: -0.1,
          num10: -0.48,
          num11: -0.16,
          num12: -0.27,
          num13: 0.33,
          num14: 0.55,
          num15: -0.19,
          num16: 0.67,
          num17: -0.12,
          num18: -0.51,
          num19: 0.15,
          num20: -0.47,
          num21: -0.14,
          num22: -0.47,
          num23: 0.01,
          num24: -0.44,
          num25: -0.16,
          num26: -0.24,
          num27: -0.14,
          num28: -0.45,
          num29: 0.43,
        },
        // 吸收速度
        {
          num1: 0.4,
          num2: 0.5,
          num3: 0.08,
          num4: -0.42,
          num5: -0.33,
          num6: -0.54,
          num7: -0.37,
          num8: -0.24,
          num9: 0.18,
          num10: -0.67,
          num11: 0.04,
          num12: -0.31,
          num13: 0.56,
          num14: 0.7,
          num15: -0.38,
          num16: 0.76,
          num17: -0.27,
          num18: -0.4,
          num19: 0.13,
          num20: -0.56,
          num21: -0.31,
          num22: -0.31,
          num23: 0.02,
          num24: -0.55,
          num25: -0.33,
          num26: -0.08,
          num27: -0.13,
          num28: -0.57,
          num29: 0.57,
        },
        // 吸收速度残留量
        {
          num1: 0.25,
          num2: 0.38,
          num3: 0.15,
          num4: -0.3,
          num5: -0.24,
          num6: -0.46,
          num7: -0.29,
          num8: -0.17,
          num9: 0.13,
          num10: -0.64,
          num11: 0.0,
          num12: -0.37,
          num13: 0.54,
          num14: 0.69,
          num15: -0.49,
          num16: 0.78,
          num17: -0.34,
          num18: -0.49,
          num19: 0.14,
          num20: -0.62,
          num21: -0.39,
          num22: -0.35,
          num23: -0.01,
          num24: -0.62,
          num25: -0.43,
          num26: -0.08,
          num27: -0.19,
          num28: -0.66,
          num29: 0.62,
        },
        // 适合敏感肌
        {
          num1: 0.35,
          num2: 0.38,
          num3: 0.31,
          num4: -0.25,
          num5: -0.18,
          num6: -0.38,
          num7: -0.25,
          num8: -0.07,
          num9: 0.15,
          num10: -0.44,
          num11: -0.0,
          num12: -0.39,
          num13: 0.35,
          num14: 0.46,
          num15: -0.25,
          num16: 0.57,
          num17: -0.16,
          num18: -0.38,
          num19: 0.17,
          num20: -0.43,
          num21: -0.17,
          num22: -0.3,
          num23: 0.04,
          num24: -0.48,
          num25: -0.18,
          num26: -0.08,
          num27: -0.1,
          num28: -0.47,
          num29: 0.44,
        },
        // 适合我的肤质"
        {
          num1: 0.37,
          num2: 0.45,
          num3: 0.29,
          num4: -0.24,
          num5: 0.08,
          num6: -0.24,
          num7: -0.44,
          num8: 0.12,
          num9: -0.08,
          num10: -0.41,
          num11: -0.3,
          num12: -0.42,
          num13: 0.38,
          num14: 0.53,
          num15: -0.24,
          num16: 0.61,
          num17: -0.2,
          num18: -0.33,
          num19: -0.12,
          num20: -0.63,
          num21: -0.2,
          num22: -0.28,
          num23: -0.25,
          num24: -0.65,
          num25: -0.25,
          num26: -0.02,
          num27: -0.29,
          num28: -0.62,
          num29: 0.6,
        },
      ],
      // 数据二  感官属性
      enheaderData2_x: [
        'Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Speed of absorption',
      ],
      enheaderData2_y: [
        'Fineness of texture',
        'Reflection of product',
        'Transparence of product',
        'Thickness/ Flow',
        'Bounce',
        'Firmness',
        'Peaking',
        'Pick up',
        'Stringy',
        'Cushion',
        'Instant whitening',
        'Covering',
        'Spreadability',
        'Product watery',
        'Product greasiness',
        'Cooling',
        'Shininess(instant)',
        'Smoothness(instant)',
        'Stickiness(instant)',
        'Residue(instant)',
        'Shininess(2min)',
        'Smoothness(2min)',
        'Stickiness(2min)',
        'Residue(2min)',
        'Shininess(5min)',
        'Smoothness(5min)',
        'Stickiness(5min)',
        'Residue(5min)',
        'Speed of absorption',
      ],
      chheaderData2_x: [
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '总体吸收速度',
      ],
      chheaderData2_y: [
        '产品质地细腻度',
        '产品光泽度',
        '产品透明度',
        '产品质地厚薄',
        '产品弹性',
        '产品软硬程度',
        '蘸起后的峰',
        '蘸起难易程度',
        '产品拉丝性',
        '小垫子',
        '增白程度',
        '产品遮盖能力',
        '涂抹难易程度',
        '产品水感',
        '产品油感',
        '皮肤清凉感',
        '皮肤光亮程度(即时)',
        '皮肤光滑程度(即时)',
        '皮肤粘手程度(即时)',
        '产品残留物的量(即时)',
        '皮肤光亮程度(2分钟)',
        '皮肤光滑程度(2分钟)',
        '皮肤粘手程度(2分钟)',
        '产品残留物的量(2分钟)',
        '皮肤光亮程度(5分钟)',
        '皮肤光滑程度(5分钟)',
        '皮肤粘手程度(5分钟)',
        '产品残留物的量(5分钟)',
        '总体吸收速度',
      ],
      data2: [
        {
          num1: 1.0,
          num2: 0.94,
          num3: 0.24,
          num4: 0.13,
          num5: 0.32,
          num6: 0.16,
          num7: -0.06,
          num8: 0.37,
          num9: 0.28,
          num10: 0.35,
          num11: 0.27,
          num12: 0.21,
          num13: 0.16,
          num14: 0.23,
          num15: 0.12,
          num16: 0.05,
          num17: 0.17,
          num18: -0.08,
          num19: 0.19,
          num20: 0.12,
          num21: 0.15,
          num22: -0.08,
          num23: 0.2,
          num24: 0.1,
          num25: 0.13,
          num26: -0.06,
          num27: 0.18,
          num28: 0.07,
          num29: -0.05,
        },
        {
          num1: 0.94,
          num2: 1.0,
          num3: 0.29,
          num4: 0.23,
          num5: 0.39,
          num6: 0.24,
          num7: 0.01,
          num8: 0.46,
          num9: 0.36,
          num10: 0.44,
          num11: 0.3,
          num12: 0.25,
          num13: 0.13,
          num14: 0.19,
          num15: 0.09,
          num16: 0.07,
          num17: 0.18,
          num18: -0.14,
          num19: 0.23,
          num20: 0.13,
          num21: 0.14,
          num22: -0.18,
          num23: 0.21,
          num24: 0.1,
          num25: 0.1,
          num26: -0.17,
          num27: 0.18,
          num28: 0.06,
          num29: -0.08,
        },
        {
          num1: 0.24,
          num2: 0.29,
          num3: 1.0,
          num4: 0.19,
          num5: 0.37,
          num6: 0.09,
          num7: -0.01,
          num8: 0.28,
          num9: -0.03,
          num10: 0.1,
          num11: -0.24,
          num12: -0.38,
          num13: 0.3,
          num14: 0.05,
          num15: -0.37,
          num16: 0.18,
          num17: -0.35,
          num18: -0.02,
          num19: -0.05,
          num20: -0.4,
          num21: -0.41,
          num22: 0.06,
          num23: -0.17,
          num24: -0.47,
          num25: -0.44,
          num26: 0.18,
          num27: -0.23,
          num28: -0.46,
          num29: 0.4,
        },
        {
          num1: 0.13,
          num2: 0.23,
          num3: 0.19,
          num4: 1.0,
          num5: 0.72,
          num6: 0.91,
          num7: 0.83,
          num8: 0.82,
          num9: 0.53,
          num10: 0.79,
          num11: 0.13,
          num12: 0.13,
          num13: -0.54,
          num14: -0.43,
          num15: -0.03,
          num16: -0.06,
          num17: -0.04,
          num18: -0.32,
          num19: 0.38,
          num20: 0.16,
          num21: -0.05,
          num22: -0.37,
          num23: 0.31,
          num24: 0.07,
          num25: -0.07,
          num26: -0.37,
          num27: 0.26,
          num28: 0.09,
          num29: -0.18,
        },
        {
          num1: 0.32,
          num2: 0.39,
          num3: 0.37,
          num4: 0.72,
          num5: 1.0,
          num6: 0.7,
          num7: 0.42,
          num8: 0.86,
          num9: 0.36,
          num10: 0.71,
          num11: 0.06,
          num12: 0.14,
          num13: -0.34,
          num14: -0.26,
          num15: -0.16,
          num16: 0.14,
          num17: -0.1,
          num18: -0.41,
          num19: 0.36,
          num20: 0.02,
          num21: -0.13,
          num22: -0.41,
          num23: 0.27,
          num24: -0.05,
          num25: -0.17,
          num26: -0.35,
          num27: 0.2,
          num28: -0.04,
          num29: -0.03,
        },
        {
          num1: 0.16,
          num2: 0.24,
          num3: 0.09,
          num4: 0.91,
          num5: 0.7,
          num6: 1.0,
          num7: 0.77,
          num8: 0.76,
          num9: 0.47,
          num10: 0.89,
          num11: 0.07,
          num12: 0.14,
          num13: -0.62,
          num14: -0.34,
          num15: 0.08,
          num16: -0.1,
          num17: 0.01,
          num18: -0.33,
          num19: 0.43,
          num20: 0.23,
          num21: 0.04,
          num22: -0.35,
          num23: 0.4,
          num24: 0.18,
          num25: 0.06,
          num26: -0.36,
          num27: 0.38,
          num28: 0.21,
          num29: -0.25,
        },
        {
          num1: -0.06,
          num2: 0.01,
          num3: -0.01,
          num4: 0.83,
          num5: 0.42,
          num6: 0.77,
          num7: 1.0,
          num8: 0.6,
          num9: 0.47,
          num10: 0.59,
          num11: 0.16,
          num12: 0.13,
          num13: -0.61,
          num14: -0.48,
          num15: 0.05,
          num16: -0.12,
          num17: -0.05,
          num18: -0.21,
          num19: 0.32,
          num20: 0.18,
          num21: -0.03,
          num22: -0.3,
          num23: 0.28,
          num24: 0.14,
          num25: -0.03,
          num26: -0.32,
          num27: 0.25,
          num28: 0.15,
          num29: -0.21,
        },
        {
          num1: 0.37,
          num2: 0.46,
          num3: 0.28,
          num4: 0.82,
          num5: 0.86,
          num6: 0.76,
          num7: 0.6,
          num8: 1.0,
          num9: 0.54,
          num10: 0.77,
          num11: 0.19,
          num12: 0.23,
          num13: -0.43,
          num14: -0.36,
          num15: -0.11,
          num16: 0.06,
          num17: -0.05,
          num18: -0.4,
          num19: 0.4,
          num20: 0.09,
          num21: -0.09,
          num22: -0.48,
          num23: 0.31,
          num24: 0.01,
          num25: -0.14,
          num26: -0.44,
          num27: 0.23,
          num28: 0.01,
          num29: -0.1,
        },
        {
          num1: 0.28,
          num2: 0.36,
          num3: -0.03,
          num4: 0.53,
          num5: 0.36,
          num6: 0.47,
          num7: 0.47,
          num8: 0.54,
          num9: 1.0,
          num10: 0.54,
          num11: 0.44,
          num12: 0.34,
          num13: -0.42,
          num14: -0.37,
          num15: 0.09,
          num16: -0.04,
          num17: 0.17,
          num18: -0.31,
          num19: 0.42,
          num20: 0.25,
          num21: 0.15,
          num22: -0.4,
          num23: 0.36,
          num24: 0.18,
          num25: 0.08,
          num26: -0.39,
          num27: 0.31,
          num28: 0.18,
          num29: -0.26,
        },
        {
          num1: 0.35,
          num2: 0.44,
          num3: 0.1,
          num4: 0.79,
          num5: 0.71,
          num6: 0.89,
          num7: 0.59,
          num8: 0.77,
          num9: 0.54,
          num10: 1.0,
          num11: 0.2,
          num12: 0.36,
          num13: -0.56,
          num14: -0.41,
          num15: 0.08,
          num16: -0.2,
          num17: 0.05,
          num18: -0.25,
          num19: 0.34,
          num20: 0.27,
          num21: 0.06,
          num22: -0.31,
          num23: 0.32,
          num24: 0.2,
          num25: 0.04,
          num26: -0.34,
          num27: 0.31,
          num28: 0.21,
          num29: -0.29,
        },
        {
          num1: 0.27,
          num2: 0.3,
          num3: -0.24,
          num4: 0.13,
          num5: 0.06,
          num6: 0.07,
          num7: 0.16,
          num8: 0.19,
          num9: 0.44,
          num10: 0.2,
          num11: 1.0,
          num12: 0.68,
          num13: -0.27,
          num14: -0.27,
          num15: 0.16,
          num16: -0.15,
          num17: 0.25,
          num18: -0.12,
          num19: 0.27,
          num20: 0.34,
          num21: 0.21,
          num22: -0.29,
          num23: 0.31,
          num24: 0.33,
          num25: 0.18,
          num26: -0.39,
          num27: 0.32,
          num28: 0.31,
          num29: -0.38,
        },
        {
          num1: 0.21,
          num2: 0.25,
          num3: -0.38,
          num4: 0.13,
          num5: 0.14,
          num6: 0.14,
          num7: 0.13,
          num8: 0.23,
          num9: 0.34,
          num10: 0.36,
          num11: 0.68,
          num12: 1.0,
          num13: -0.27,
          num14: -0.31,
          num15: 0.19,
          num16: -0.28,
          num17: 0.29,
          num18: -0.01,
          num19: 0.11,
          num20: 0.47,
          num21: 0.25,
          num22: -0.18,
          num23: 0.17,
          num24: 0.44,
          num25: 0.2,
          num26: -0.3,
          num27: 0.2,
          num28: 0.39,
          num29: -0.48,
        },
        {
          num1: 0.16,
          num2: 0.13,
          num3: 0.3,
          num4: -0.54,
          num5: -0.34,
          num6: -0.62,
          num7: -0.61,
          num8: -0.43,
          num9: -0.42,
          num10: -0.56,
          num11: -0.27,
          num12: -0.27,
          num13: 1.0,
          num14: 0.56,
          num15: -0.12,
          num16: 0.22,
          num17: 0.03,
          num18: 0.4,
          num19: -0.4,
          num20: -0.26,
          num21: -0.05,
          num22: 0.44,
          num23: -0.41,
          num24: -0.26,
          num25: -0.08,
          num26: 0.45,
          num27: -0.39,
          num28: -0.3,
          num29: 0.32,
        },
        {
          num1: 0.23,
          num2: 0.19,
          num3: 0.05,
          num4: -0.43,
          num5: -0.26,
          num6: -0.34,
          num7: -0.48,
          num8: -0.36,
          num9: -0.37,
          num10: -0.41,
          num11: -0.27,
          num12: -0.31,
          num13: 0.56,
          num14: 1.0,
          num15: -0.19,
          num16: 0.43,
          num17: -0.09,
          num18: 0.01,
          num19: -0.1,
          num20: -0.24,
          num21: -0.05,
          num22: 0.16,
          num23: -0.09,
          num24: -0.16,
          num25: 0.02,
          num26: 0.25,
          num27: -0.1,
          num28: -0.16,
          num29: 0.33,
        },
        {
          num1: 0.12,
          num2: 0.09,
          num3: -0.37,
          num4: -0.03,
          num5: -0.16,
          num6: 0.08,
          num7: 0.05,
          num8: -0.11,
          num9: 0.09,
          num10: 0.08,
          num11: 0.16,
          num12: 0.19,
          num13: -0.12,
          num14: -0.19,
          num15: 1.0,
          num16: -0.33,
          num17: 0.83,
          num18: 0.25,
          num19: 0.18,
          num20: 0.77,
          num21: 0.86,
          num22: 0.16,
          num23: 0.34,
          num24: 0.79,
          num25: 0.85,
          num26: -0.03,
          num27: 0.46,
          num28: 0.78,
          num29: -0.74,
        },
        {
          num1: 0.05,
          num2: 0.07,
          num3: 0.18,
          num4: -0.06,
          num5: 0.14,
          num6: -0.1,
          num7: -0.12,
          num8: 0.06,
          num9: -0.04,
          num10: -0.2,
          num11: -0.15,
          num12: -0.28,
          num13: 0.22,
          num14: 0.43,
          num15: -0.33,
          num16: 1.0,
          num17: -0.14,
          num18: -0.42,
          num19: 0.21,
          num20: -0.3,
          num21: -0.2,
          num22: -0.31,
          num23: 0.06,
          num24: -0.29,
          num25: -0.25,
          num26: -0.12,
          num27: -0.06,
          num28: -0.32,
          num29: 0.41,
        },
        {
          num1: 0.17,
          num2: 0.18,
          num3: -0.35,
          num4: -0.04,
          num5: -0.1,
          num6: 0.01,
          num7: -0.05,
          num8: -0.05,
          num9: 0.17,
          num10: 0.05,
          num11: 0.25,
          num12: 0.29,
          num13: 0.03,
          num14: -0.09,
          num15: 0.83,
          num16: -0.14,
          num17: 1.0,
          num18: 0.16,
          num19: 0.21,
          num20: 0.8,
          num21: 0.97,
          num22: 0.08,
          num23: 0.32,
          num24: 0.79,
          num25: 0.9,
          num26: -0.06,
          num27: 0.41,
          num28: 0.76,
          num29: -0.75,
        },
        {
          num1: -0.08,
          num2: -0.14,
          num3: -0.02,
          num4: -0.32,
          num5: -0.41,
          num6: -0.33,
          num7: -0.21,
          num8: -0.4,
          num9: -0.31,
          num10: -0.25,
          num11: -0.12,
          num12: -0.01,
          num13: 0.4,
          num14: 0.01,
          num15: 0.25,
          num16: -0.42,
          num17: 0.16,
          num18: 1.0,
          num19: -0.74,
          num20: 0.01,
          num21: 0.16,
          num22: 0.91,
          num23: -0.61,
          num24: 0.03,
          num25: 0.15,
          num26: 0.71,
          num27: -0.45,
          num28: 0.03,
          num29: 0.0,
        },
        {
          num1: 0.19,
          num2: 0.23,
          num3: -0.05,
          num4: 0.38,
          num5: 0.36,
          num6: 0.43,
          num7: 0.32,
          num8: 0.4,
          num9: 0.42,
          num10: 0.34,
          num11: 0.27,
          num12: 0.11,
          num13: -0.4,
          num14: -0.1,
          num15: 0.18,
          num16: 0.21,
          num17: 0.21,
          num18: -0.74,
          num19: 1.0,
          num20: 0.45,
          num21: 0.22,
          num22: -0.76,
          num23: 0.93,
          num24: 0.39,
          num25: 0.23,
          num26: -0.7,
          num27: 0.82,
          num28: 0.39,
          num29: -0.38,
        },
        {
          num1: 0.12,
          num2: 0.13,
          num3: -0.4,
          num4: 0.16,
          num5: 0.02,
          num6: 0.23,
          num7: 0.18,
          num8: 0.09,
          num9: 0.25,
          num10: 0.27,
          num11: 0.34,
          num12: 0.47,
          num13: -0.26,
          num14: -0.24,
          num15: 0.77,
          num16: -0.3,
          num17: 0.8,
          num18: 0.01,
          num19: 0.45,
          num20: 1.0,
          num21: 0.83,
          num22: -0.11,
          num23: 0.56,
          num24: 0.96,
          num25: 0.82,
          num26: -0.28,
          num27: 0.64,
          num28: 0.93,
          num29: -0.91,
        },
        {
          num1: 0.15,
          num2: 0.14,
          num3: -0.41,
          num4: -0.05,
          num5: -0.13,
          num6: 0.04,
          num7: -0.03,
          num8: -0.09,
          num9: 0.15,
          num10: 0.06,
          num11: 0.21,
          num12: 0.25,
          num13: -0.05,
          num14: -0.05,
          num15: 0.86,
          num16: -0.2,
          num17: 0.97,
          num18: 0.16,
          num19: 0.22,
          num20: 0.83,
          num21: 1.0,
          num22: 0.09,
          num23: 0.37,
          num24: 0.86,
          num25: 0.96,
          num26: -0.06,
          num27: 0.48,
          num28: 0.85,
          num29: -0.8,
        },
        {
          num1: -0.08,
          num2: -0.18,
          num3: 0.06,
          num4: -0.37,
          num5: -0.41,
          num6: -0.35,
          num7: -0.3,
          num8: -0.48,
          num9: -0.4,
          num10: -0.31,
          num11: -0.29,
          num12: -0.18,
          num13: 0.44,
          num14: 0.16,
          num15: 0.16,
          num16: -0.31,
          num17: 0.08,
          num18: 0.91,
          num19: -0.76,
          num20: -0.11,
          num21: 0.09,
          num22: 1.0,
          num23: -0.67,
          num24: -0.08,
          num25: 0.11,
          num26: 0.9,
          num27: -0.54,
          num28: -0.07,
          num29: 0.12,
        },
        {
          num1: 0.2,
          num2: 0.21,
          num3: -0.17,
          num4: 0.31,
          num5: 0.27,
          num6: 0.4,
          num7: 0.28,
          num8: 0.31,
          num9: 0.36,
          num10: 0.32,
          num11: 0.31,
          num12: 0.17,
          num13: -0.41,
          num14: -0.09,
          num15: 0.34,
          num16: 0.06,
          num17: 0.32,
          num18: -0.61,
          num19: 0.93,
          num20: 0.56,
          num21: 0.37,
          num22: -0.67,
          num23: 1.0,
          num24: 0.56,
          num25: 0.41,
          num26: -0.69,
          num27: 0.95,
          num28: 0.57,
          num29: -0.52,
        },
        {
          num1: 0.1,
          num2: 0.1,
          num3: -0.47,
          num4: 0.07,
          num5: -0.05,
          num6: 0.18,
          num7: 0.14,
          num8: 0.01,
          num9: 0.18,
          num10: 0.2,
          num11: 0.33,
          num12: 0.44,
          num13: -0.26,
          num14: -0.16,
          num15: 0.79,
          num16: -0.29,
          num17: 0.79,
          num18: 0.03,
          num19: 0.39,
          num20: 0.96,
          num21: 0.86,
          num22: -0.08,
          num23: 0.56,
          num24: 1.0,
          num25: 0.88,
          num26: -0.27,
          num27: 0.65,
          num28: 0.98,
          num29: -0.92,
        },
        {
          num1: 0.13,
          num2: 0.1,
          num3: -0.44,
          num4: -0.07,
          num5: -0.17,
          num6: 0.06,
          num7: -0.03,
          num8: -0.14,
          num9: 0.08,
          num10: 0.04,
          num11: 0.18,
          num12: 0.2,
          num13: -0.08,
          num14: 0.02,
          num15: 0.85,
          num16: -0.25,
          num17: 0.9,
          num18: 0.15,
          num19: 0.23,
          num20: 0.82,
          num21: 0.96,
          num22: 0.11,
          num23: 0.41,
          num24: 0.88,
          num25: 1.0,
          num26: -0.04,
          num27: 0.52,
          num28: 0.89,
          num29: -0.81,
        },
        {
          num1: -0.06,
          num2: -0.17,
          num3: 0.18,
          num4: -0.37,
          num5: -0.35,
          num6: -0.36,
          num7: -0.32,
          num8: -0.44,
          num9: -0.39,
          num10: -0.34,
          num11: -0.39,
          num12: -0.3,
          num13: 0.45,
          num14: 0.25,
          num15: -0.03,
          num16: -0.12,
          num17: -0.06,
          num18: 0.71,
          num19: -0.7,
          num20: -0.28,
          num21: -0.06,
          num22: 0.9,
          num23: -0.69,
          num24: -0.27,
          num25: -0.04,
          num26: 1.0,
          num27: -0.64,
          num28: -0.26,
          num29: 0.31,
        },
        {
          num1: 0.18,
          num2: 0.18,
          num3: -0.23,
          num4: 0.26,
          num5: 0.2,
          num6: 0.38,
          num7: 0.25,
          num8: 0.23,
          num9: 0.31,
          num10: 0.31,
          num11: 0.32,
          num12: 0.2,
          num13: -0.39,
          num14: -0.1,
          num15: 0.46,
          num16: -0.06,
          num17: 0.41,
          num18: -0.45,
          num19: 0.82,
          num20: 0.64,
          num21: 0.48,
          num22: -0.54,
          num23: 0.95,
          num24: 0.65,
          num25: 0.52,
          num26: -0.64,
          num27: 1.0,
          num28: 0.68,
          num29: -0.61,
        },
        {
          num1: 0.07,
          num2: 0.06,
          num3: -0.46,
          num4: 0.09,
          num5: -0.04,
          num6: 0.21,
          num7: 0.15,
          num8: 0.01,
          num9: 0.18,
          num10: 0.21,
          num11: 0.31,
          num12: 0.39,
          num13: -0.3,
          num14: -0.16,
          num15: 0.78,
          num16: -0.32,
          num17: 0.76,
          num18: 0.03,
          num19: 0.39,
          num20: 0.93,
          num21: 0.85,
          num22: -0.07,
          num23: 0.57,
          num24: 0.98,
          num25: 0.89,
          num26: -0.26,
          num27: 0.68,
          num28: 1.0,
          num29: -0.93,
        },
        {
          num1: -0.05,
          num2: -0.08,
          num3: 0.4,
          num4: -0.18,
          num5: -0.03,
          num6: -0.25,
          num7: -0.21,
          num8: -0.1,
          num9: -0.26,
          num10: -0.29,
          num11: -0.38,
          num12: -0.48,
          num13: 0.32,
          num14: 0.33,
          num15: -0.74,
          num16: 0.41,
          num17: -0.75,
          num18: -0.0,
          num19: -0.38,
          num20: -0.91,
          num21: -0.8,
          num22: 0.12,
          num23: -0.52,
          num24: -0.92,
          num25: -0.81,
          num26: 0.31,
          num27: -0.61,
          num28: -0.93,
          num29: 1.0,
        },
      ],
      // ! 消费者数据
      enheaderData3_x: [
        'Overall liking',
        'Purchase intenison INTENSITY',
        'Overall usage experience HEDONIC',
        'Appearance HEDONIC',
        'Color HEDONIC',
        'Texture HEDONIC',
        'Skin feel after use HEDONIC',
        'Moisturizing HEDONIC',
        'Hydrating HEDONIC',
        'Stickness HEDONIC',
        'Oiliness HEDONIC',
        'Whitening HEDONIC',
        'Smoothness HEDONIC',
        'Brightening/radiance HEDONIC',
        'Cooling HEDONIC',
        'Absorption speed HEDONIC',
        'Residue HEDONIC',
        'Ball up  INTENSITY',
        'Suitable for senstive skin HEDONIC',
      ],
      enheaderData3_y: [
        'Overall liking',
        'Purchase intenison INTENSITY',
        'Overall usage experience HEDONIC',
        'Appearance HEDONIC',
        'Color HEDONIC',
        'Texture HEDONIC',
        'Skin feel after use HEDONIC',
        'Moisturizing HEDONIC',
        'Hydrating HEDONIC',
        'Stickness HEDONIC',
        'Oiliness HEDONIC',
        'Whitening HEDONIC',
        'Smoothness HEDONIC',
        'Brightening/radiance HEDONIC',
        'Cooling HEDONIC',
        'Absorption speed HEDONIC',
        'Residue HEDONIC',
        'Ball up  INTENSITY',
        'Suitable for senstive skin HEDONIC',
      ],
      chheaderData3_x: [
        '整体喜好度',
        '购买意愿 INTENSITY',
        '整体使用体验 HEDONIC',
        '产品外观 HEDONIC',
        '产品颜色 HEDONIC',
        '产品质地 HEDONIC',
        '使用后的肤感 HEDONIC',
        '滋润感 HEDONIC',
        '水润感 HEDONIC',
        '粘腻度 HEDONIC',
        '油感 HEDONIC',
        '增白效果 HEDONIC',
        '顺滑度/光滑度 HEDONIC',
        '提亮效果/光泽度 HEDONIC',
        '清凉感 HEDONIC',
        '吸收速度 HEDONIC',
        '残留量 HEDONIC',

        '适合敏感肌 HEDONIC',
        '适合我的肤质 HEDONIC',
      ],
      chheaderData3_y: [
        '整体喜好度',
        '购买意愿 INTENSITY',
        '整体使用体验 HEDONIC',
        '产品外观 HEDONIC',
        '产品颜色 HEDONIC',
        '产品质地 HEDONIC',
        '使用后的肤感 HEDONIC',
        '滋润感 HEDONIC',
        '水润感 HEDONIC',
        '粘腻度 HEDONIC',
        '油感 HEDONIC',
        '增白效果 HEDONIC',
        '顺滑度/光滑度 HEDONIC',
        '提亮效果/光泽度 HEDONIC',
        '清凉感 HEDONIC',
        '吸收速度 HEDONIC',
        '残留量 HEDONIC',

        '适合敏感肌 HEDONIC',
        '适合我的肤质 HEDONIC',
      ],
      data3: [
        {
          num1: 1.0,
          num2: 0.66,
          num3: 0.82,
          num4: 0.61,
          num5: 0.54,
          num6: 0.68,
          num7: 0.76,
          num8: 0.62,
          num9: 0.66,
          num10: 0.61,
          num11: 0.62,
          num12: 0.56,
          num13: 0.65,
          num14: 0.57,
          num15: 0.61,
          num16: 0.63,
          num17: 0.65,
          num18: 0.59,
          num19: 0.78,
        },
        {
          num1: 0.66,
          num2: 1.0,
          num3: 0.64,
          num4: 0.53,
          num5: 0.51,
          num6: 0.59,
          num7: 0.63,
          num8: 0.54,
          num9: 0.54,
          num10: 0.56,
          num11: 0.55,
          num12: 0.51,
          num13: 0.58,
          num14: 0.53,
          num15: 0.49,
          num16: 0.57,
          num17: 0.57,
          num18: 0.49,
          num19: 0.65,
        },
        {
          num1: 0.82,
          num2: 0.64,
          num3: 1.0,
          num4: 0.6,
          num5: 0.52,
          num6: 0.67,
          num7: 0.8,
          num8: 0.62,
          num9: 0.68,
          num10: 0.63,
          num11: 0.64,
          num12: 0.52,
          num13: 0.67,
          num14: 0.53,
          num15: 0.6,
          num16: 0.65,
          num17: 0.66,
          num18: 0.6,
          num19: 0.79,
        },
        {
          num1: 0.61,
          num2: 0.53,
          num3: 0.6,
          num4: 1.0,
          num5: 0.68,
          num6: 0.62,
          num7: 0.59,
          num8: 0.52,
          num9: 0.54,
          num10: 0.5,
          num11: 0.52,
          num12: 0.5,
          num13: 0.53,
          num14: 0.54,
          num15: 0.46,
          num16: 0.52,
          num17: 0.55,
          num18: 0.51,
          num19: 0.58,
        },
        {
          num1: 0.54,
          num2: 0.51,
          num3: 0.52,
          num4: 0.68,
          num5: 1.0,
          num6: 0.53,
          num7: 0.48,
          num8: 0.45,
          num9: 0.54,
          num10: 0.4,
          num11: 0.45,
          num12: 0.51,
          num13: 0.48,
          num14: 0.51,
          num15: 0.46,
          num16: 0.41,
          num17: 0.45,
          num18: 0.46,
          num19: 0.59,
        },
        {
          num1: 0.68,
          num2: 0.59,
          num3: 0.67,
          num4: 0.62,
          num5: 0.53,
          num6: 1.0,
          num7: 0.63,
          num8: 0.58,
          num9: 0.66,
          num10: 0.52,
          num11: 0.56,
          num12: 0.44,
          num13: 0.6,
          num14: 0.47,
          num15: 0.52,
          num16: 0.55,
          num17: 0.56,
          num18: 0.51,
          num19: 0.67,
        },
        {
          num1: 0.76,
          num2: 0.63,
          num3: 0.8,
          num4: 0.59,
          num5: 0.48,
          num6: 0.63,
          num7: 1.0,
          num8: 0.64,
          num9: 0.69,
          num10: 0.67,
          num11: 0.67,
          num12: 0.52,
          num13: 0.7,
          num14: 0.53,
          num15: 0.61,
          num16: 0.64,
          num17: 0.7,
          num18: 0.6,
          num19: 0.79,
        },
        {
          num1: 0.62,
          num2: 0.54,
          num3: 0.62,
          num4: 0.52,
          num5: 0.45,
          num6: 0.58,
          num7: 0.64,
          num8: 1.0,
          num9: 0.73,
          num10: 0.55,
          num11: 0.57,
          num12: 0.45,
          num13: 0.62,
          num14: 0.48,
          num15: 0.49,
          num16: 0.57,
          num17: 0.57,
          num18: 0.5,
          num19: 0.64,
        },
        {
          num1: 0.66,
          num2: 0.54,
          num3: 0.68,
          num4: 0.54,
          num5: 0.54,
          num6: 0.66,
          num7: 0.69,
          num8: 0.73,
          num9: 1.0,
          num10: 0.64,
          num11: 0.63,
          num12: 0.51,
          num13: 0.67,
          num14: 0.53,
          num15: 0.49,
          num16: 0.64,
          num17: 0.63,
          num18: 0.54,
          num19: 0.67,
        },
        {
          num1: 0.61,
          num2: 0.56,
          num3: 0.63,
          num4: 0.5,
          num5: 0.4,
          num6: 0.52,
          num7: 0.67,
          num8: 0.55,
          num9: 0.64,
          num10: 1.0,
          num11: 0.73,
          num12: 0.43,
          num13: 0.65,
          num14: 0.45,
          num15: 0.52,
          num16: 0.62,
          num17: 0.67,
          num18: 0.51,
          num19: 0.67,
        },
        {
          num1: 0.62,
          num2: 0.55,
          num3: 0.64,
          num4: 0.52,
          num5: 0.45,
          num6: 0.56,
          num7: 0.67,
          num8: 0.57,
          num9: 0.63,
          num10: 0.73,
          num11: 1.0,
          num12: 0.45,
          num13: 0.62,
          num14: 0.46,
          num15: 0.52,
          num16: 0.61,
          num17: 0.68,
          num18: 0.51,
          num19: 0.67,
        },
        {
          num1: 0.56,
          num2: 0.51,
          num3: 0.52,
          num4: 0.5,
          num5: 0.51,
          num6: 0.44,
          num7: 0.52,
          num8: 0.45,
          num9: 0.51,
          num10: 0.43,
          num11: 0.45,
          num12: 1.0,
          num13: 0.46,
          num14: 0.76,
          num15: 0.45,
          num16: 0.44,
          num17: 0.49,
          num18: 0.42,
          num19: 0.56,
        },
        {
          num1: 0.65,
          num2: 0.58,
          num3: 0.67,
          num4: 0.53,
          num5: 0.48,
          num6: 0.6,
          num7: 0.7,
          num8: 0.62,
          num9: 0.67,
          num10: 0.65,
          num11: 0.62,
          num12: 0.46,
          num13: 1.0,
          num14: 0.49,
          num15: 0.52,
          num16: 0.63,
          num17: 0.66,
          num18: 0.53,
          num19: 0.67,
        },
        {
          num1: 0.57,
          num2: 0.53,
          num3: 0.53,
          num4: 0.54,
          num5: 0.51,
          num6: 0.47,
          num7: 0.53,
          num8: 0.48,
          num9: 0.53,
          num10: 0.45,
          num11: 0.46,
          num12: 0.76,
          num13: 0.49,
          num14: 1.0,
          num15: 0.47,
          num16: 0.46,
          num17: 0.49,
          num18: 0.45,
          num19: 0.59,
        },
        {
          num1: 0.61,
          num2: 0.49,
          num3: 0.6,
          num4: 0.46,
          num5: 0.46,
          num6: 0.52,
          num7: 0.61,
          num8: 0.49,
          num9: 0.49,
          num10: 0.52,
          num11: 0.52,
          num12: 0.45,
          num13: 0.52,
          num14: 0.47,
          num15: 1.0,
          num16: 0.51,
          num17: 0.53,
          num18: 0.49,
          num19: 0.58,
        },
        {
          num1: 0.63,
          num2: 0.57,
          num3: 0.65,
          num4: 0.52,
          num5: 0.41,
          num6: 0.55,
          num7: 0.64,
          num8: 0.57,
          num9: 0.64,
          num10: 0.62,
          num11: 0.61,
          num12: 0.44,
          num13: 0.63,
          num14: 0.46,
          num15: 0.51,
          num16: 1.0,
          num17: 0.65,
          num18: 0.51,
          num19: 0.66,
        },
        {
          num1: 0.65,
          num2: 0.57,
          num3: 0.66,
          num4: 0.55,
          num5: 0.45,
          num6: 0.56,
          num7: 0.7,
          num8: 0.57,
          num9: 0.63,
          num10: 0.67,
          num11: 0.67,
          num12: 0.49,
          num13: 0.66,
          num14: 0.49,
          num15: 0.53,
          num16: 0.65,
          num17: 1.0,
          num18: 0.53,
          num19: 0.68,
        },
        {
          num1: 0.59,
          num2: 0.49,
          num3: 0.6,
          num4: 0.51,
          num5: 0.46,
          num6: 0.51,
          num7: 0.6,
          num8: 0.5,
          num9: 0.54,
          num10: 0.51,
          num11: 0.51,
          num12: 0.42,
          num13: 0.53,
          num14: 0.45,
          num15: 0.49,
          num16: 0.51,
          num17: 0.53,
          num18: 1.0,
          num19: 0.68,
        },
        {
          num1: 0.78,
          num2: 0.65,
          num3: 0.79,
          num4: 0.58,
          num5: 0.59,
          num6: 0.67,
          num7: 0.79,
          num8: 0.64,
          num9: 0.67,
          num10: 0.67,
          num11: 0.67,
          num12: 0.56,
          num13: 0.67,
          num14: 0.59,
          num15: 0.58,
          num16: 0.66,
          num17: 0.68,
          num18: 0.68,
          num19: 1.0,
        },
      ],
      chartInfo: 0, //1网络图2热力图
      info: 8,
      num: null, //移入的下标
      rgbarr: [
        [
          [49, 54, 149],
          [54, 71, 157],
          [213, 237, 245],
          [236, 247, 225],
          [192, 227, 239],
          [230, 245, 236],
          [254, 245, 177],
          [178, 220, 235],
          [201, 231, 241],
          [184, 223, 236],
          [205, 233, 242],
          [221, 241, 247],
          [230, 245, 236],
          [213, 237, 245],
          [236, 247, 225],
          [247, 251, 205],
          [227, 244, 241],
          [254, 242, 171],
          [225, 243, 245],
          [236, 247, 225],
          [231, 245, 234],
          [254, 242, 171],
          [224, 243, 247],
          [239, 249, 218],
          [234, 247, 227],
          [254, 245, 177],
          [226, 243, 243],
          [243, 250, 212],
          [254, 247, 179],
          [54, 71, 157],
          [49, 54, 149],
          [199, 230, 240],
          [215, 238, 245],
          [174, 218, 233],
          [211, 236, 244],
          [253, 254, 194],
          [152, 202, 225],
          [180, 221, 235],
          [159, 207, 227],
          [196, 229, 240],
          [209, 235, 243],
          [236, 247, 225],
          [225, 243, 245],
          [241, 249, 216],
          [243, 250, 212],
          [226, 243, 243],
          [254, 232, 156],
          [215, 238, 245],
          [234, 247, 227],
          [232, 246, 232],
          [254, 227, 149],
          [219, 240, 246],
          [239, 249, 218],
          [239, 249, 218],
          [254, 230, 153],
          [226, 243, 243],
          [244, 251, 210],
          [254, 243, 173],
          [213, 237, 245],
          [199, 230, 240],
          [49, 54, 149],
          [225, 243, 245],
          [178, 220, 235],
          [242, 250, 214],
          [254, 254, 190],
          [201, 231, 241],
          [254, 250, 184],
          [239, 249, 218],
          [253, 214, 134],
          [253, 176, 99],
          [196, 229, 240],
          [247, 251, 205],
          [253, 180, 103],
          [226, 243, 243],
          [253, 186, 108],
          [254, 251, 186],
          [254, 247, 179],
          [253, 174, 97],
          [252, 172, 96],
          [244, 251, 210],
          [254, 228, 151],
          [249, 149, 85],
          [251, 159, 90],
          [227, 244, 241],
          [253, 216, 136],
          [250, 152, 86],
          [169, 216, 232],
          [236, 247, 225],
          [215, 238, 245],
          [225, 243, 245],
          [49, 54, 149],
          [84, 135, 189],
          [56, 78, 161],
          [65, 105, 174],
          [66, 108, 175],
          [133, 186, 216],
          [69, 118, 180],
          [234, 247, 227],
          [234, 247, 227],
          [246, 126, 75],
          [251, 162, 91],
          [254, 250, 184],
          [254, 244, 175],
          [254, 248, 180],
          [253, 192, 114],
          [174, 218, 233],
          [230, 245, 236],
          [254, 247, 179],
          [253, 178, 101],
          [194, 228, 239],
          [243, 250, 212],
          [254, 243, 173],
          [253, 180, 103],
          [205, 233, 242],
          [241, 249, 216],
          [254, 227, 149],
          [192, 227, 239],
          [174, 218, 233],
          [178, 220, 235],
          [84, 135, 189],
          [49, 54, 149],
          [90, 142, 193],
          [165, 212, 230],
          [62, 96, 169],
          [182, 222, 236],
          [86, 137, 190],
          [244, 251, 210],
          [233, 246, 230],
          [253, 186, 108],
          [253, 208, 129],
          [254, 230, 153],
          [232, 246, 232],
          [254, 238, 166],
          [252, 172, 96],
          [180, 221, 235],
          [251, 253, 196],
          [254, 233, 158],
          [252, 170, 95],
          [205, 233, 242],
          [254, 247, 179],
          [254, 228, 151],
          [253, 184, 107],
          [221, 241, 247],
          [254, 249, 182],
          [254, 250, 184],
          [230, 245, 236],
          [211, 236, 244],
          [242, 250, 214],
          [56, 78, 161],
          [90, 142, 193],
          [49, 54, 149],
          [75, 124, 183],
          [75, 124, 183],
          [150, 200, 224],
          [59, 88, 166],
          [244, 251, 210],
          [232, 246, 232],
          [240, 101, 63],
          [253, 188, 110],
          [243, 250, 212],
          [254, 238, 166],
          [253, 254, 194],
          [253, 190, 112],
          [163, 210, 229],
          [213, 237, 245],
          [247, 251, 205],
          [253, 184, 107],
          [169, 216, 232],
          [226, 243, 243],
          [245, 251, 207],
          [253, 180, 103],
          [176, 219, 234],
          [219, 240, 246],
          [253, 210, 131],
          [254, 245, 177],
          [253, 254, 194],
          [254, 254, 190],
          [65, 105, 174],
          [165, 212, 230],
          [75, 124, 183],
          [49, 54, 149],
          [116, 173, 209],
          [150, 200, 224],
          [118, 174, 209],
          [230, 245, 236],
          [234, 247, 227],
          [241, 104, 64],
          [249, 147, 84],
          [247, 251, 205],
          [254, 236, 162],
          [254, 245, 177],
          [253, 222, 142],
          [192, 227, 239],
          [226, 243, 243],
          [254, 250, 184],
          [253, 198, 120],
          [201, 231, 241],
          [233, 246, 230],
          [254, 250, 184],
          [253, 192, 114],
          [209, 235, 243],
          [231, 245, 234],
          [253, 222, 142],
          [178, 220, 235],
          [152, 202, 225],
          [201, 231, 241],
          [66, 108, 175],
          [62, 96, 169],
          [75, 124, 183],
          [116, 173, 209],
          [49, 54, 149],
          [131, 185, 215],
          [75, 124, 183],
          [225, 243, 245],
          [215, 238, 245],
          [251, 165, 92],
          [253, 182, 105],
          [254, 238, 166],
          [245, 251, 207],
          [254, 247, 179],
          [252, 172, 96],
          [169, 216, 232],
          [241, 249, 216],
          [254, 241, 169],
          [249, 147, 84],
          [194, 228, 239],
          [253, 254, 194],
          [254, 232, 156],
          [251, 162, 91],
          [213, 237, 245],
          [253, 254, 194],
          [254, 238, 166],
          [201, 231, 241],
          [180, 221, 235],
          [254, 250, 184],
          [133, 186, 216],
          [182, 222, 236],
          [150, 200, 224],
          [150, 200, 224],
          [131, 185, 215],
          [49, 54, 149],
          [131, 185, 215],
          [159, 207, 227],
          [186, 224, 237],
          [252, 167, 94],
          [253, 178, 101],
          [242, 250, 214],
          [254, 249, 182],
          [228, 244, 239],
          [253, 196, 118],
          [165, 212, 230],
          [209, 235, 243],
          [231, 245, 234],
          [253, 174, 97],
          [180, 221, 235],
          [226, 243, 243],
          [242, 250, 214],
          [253, 174, 97],
          [194, 228, 239],
          [227, 244, 241],
          [253, 208, 129],
          [184, 223, 236],
          [159, 207, 227],
          [239, 249, 218],
          [69, 118, 180],
          [86, 137, 190],
          [59, 88, 166],
          [118, 174, 209],
          [75, 124, 183],
          [131, 185, 215],
          [49, 54, 149],
          [224, 243, 247],
          [180, 221, 235],
          [245, 119, 71],
          [252, 170, 95],
          [243, 250, 212],
          [253, 222, 142],
          [247, 251, 205],
          [253, 210, 131],
          [186, 224, 237],
          [205, 233, 242],
          [245, 251, 207],
          [253, 194, 116],
          [192, 227, 239],
          [224, 243, 247],
          [248, 252, 203],
          [253, 186, 108],
          [194, 228, 239],
          [221, 241, 247],
          [253, 200, 121],
          [205, 233, 242],
          [196, 229, 240],
          [253, 214, 134],
          [234, 247, 227],
          [244, 251, 210],
          [244, 251, 210],
          [230, 245, 236],
          [225, 243, 245],
          [159, 207, 227],
          [224, 243, 247],
          [49, 54, 149],
          [95, 148, 196],
          [253, 204, 125],
          [253, 204, 125],
          [230, 245, 236],
          [254, 231, 155],
          [209, 235, 243],
          [254, 234, 160],
          [205, 233, 242],
          [186, 224, 237],
          [219, 240, 246],
          [253, 200, 121],
          [194, 228, 239],
          [188, 225, 238],
          [226, 243, 243],
          [253, 174, 97],
          [192, 227, 239],
          [194, 228, 239],
          [253, 178, 101],
          [221, 241, 247],
          [209, 235, 243],
          [253, 176, 99],
          [234, 247, 227],
          [233, 246, 230],
          [232, 246, 232],
          [234, 247, 227],
          [215, 238, 245],
          [186, 224, 237],
          [180, 221, 235],
          [95, 148, 196],
          [49, 54, 149],
          [253, 206, 127],
          [253, 194, 116],
          [225, 243, 245],
          [253, 204, 125],
          [199, 230, 240],
          [254, 254, 190],
          [238, 248, 221],
          [150, 200, 224],
          [209, 235, 243],
          [254, 226, 147],
          [228, 244, 239],
          [159, 207, 227],
          [221, 241, 247],
          [253, 198, 120],
          [221, 241, 247],
          [172, 217, 233],
          [249, 147, 84],
          [230, 245, 236],
          [236, 247, 225],
          [196, 229, 240],
          [246, 126, 75],
          [253, 186, 108],
          [240, 101, 63],
          [241, 104, 64],
          [251, 165, 92],
          [252, 167, 94],
          [245, 119, 71],
          [253, 204, 125],
          [253, 206, 127],
          [49, 54, 149],
          [126, 181, 213],
          [254, 236, 162],
          [217, 239, 246],
          [250, 253, 198],
          [169, 216, 232],
          [253, 174, 97],
          [253, 208, 129],
          [254, 247, 179],
          [156, 205, 226],
          [252, 172, 96],
          [253, 210, 131],
          [254, 242, 171],
          [154, 204, 225],
          [253, 174, 97],
          [253, 198, 120],
          [190, 226, 238],
          [213, 237, 245],
          [225, 243, 245],
          [247, 251, 205],
          [251, 162, 91],
          [253, 208, 129],
          [253, 188, 110],
          [249, 147, 84],
          [253, 182, 105],
          [253, 178, 101],
          [252, 170, 95],
          [253, 204, 125],
          [253, 194, 116],
          [126, 181, 213],
          [49, 54, 149],
          [254, 225, 145],
          [161, 209, 228],
          [254, 241, 169],
          [253, 254, 194],
          [254, 239, 167],
          [253, 214, 134],
          [254, 247, 179],
          [231, 245, 234],
          [254, 239, 167],
          [254, 230, 153],
          [251, 253, 196],
          [209, 235, 243],
          [254, 239, 167],
          [254, 230, 153],
          [190, 226, 238],
          [236, 247, 225],
          [241, 249, 216],
          [253, 180, 103],
          [254, 250, 184],
          [254, 230, 153],
          [243, 250, 212],
          [247, 251, 205],
          [254, 238, 166],
          [242, 250, 214],
          [243, 250, 212],
          [230, 245, 236],
          [225, 243, 245],
          [254, 236, 162],
          [254, 225, 145],
          [49, 54, 149],
          [253, 190, 112],
          [65, 105, 174],
          [211, 236, 244],
          [227, 244, 241],
          [75, 124, 183],
          [62, 96, 169],
          [230, 245, 236],
          [186, 224, 237],
          [69, 118, 180],
          [63, 100, 172],
          [254, 249, 182],
          [152, 202, 225],
          [71, 120, 181],
          [222, 63, 46],
          [247, 251, 205],
          [243, 250, 212],
          [226, 243, 243],
          [254, 244, 175],
          [232, 246, 232],
          [254, 238, 166],
          [254, 236, 162],
          [245, 251, 207],
          [254, 249, 182],
          [253, 222, 142],
          [254, 231, 155],
          [253, 204, 125],
          [217, 239, 246],
          [161, 209, 228],
          [253, 190, 112],
          [49, 54, 149],
          [254, 232, 156],
          [251, 165, 92],
          [221, 241, 247],
          [253, 198, 120],
          [254, 224, 144],
          [253, 194, 116],
          [244, 251, 210],
          [253, 200, 121],
          [253, 212, 132],
          [254, 236, 162],
          [254, 245, 177],
          [253, 192, 114],
          [167, 214, 231],
          [227, 244, 241],
          [226, 243, 243],
          [253, 186, 108],
          [254, 248, 180],
          [254, 238, 166],
          [253, 254, 194],
          [254, 245, 177],
          [254, 247, 179],
          [228, 244, 239],
          [247, 251, 205],
          [209, 235, 243],
          [199, 230, 240],
          [250, 253, 198],
          [254, 241, 169],
          [65, 105, 174],
          [254, 232, 156],
          [49, 54, 149],
          [230, 245, 236],
          [221, 241, 247],
          [68, 115, 179],
          [51, 61, 152],
          [243, 250, 212],
          [192, 227, 239],
          [69, 118, 180],
          [58, 83, 163],
          [254, 244, 175],
          [165, 212, 230],
          [75, 124, 183],
          [221, 61, 45],
          [254, 242, 171],
          [254, 232, 156],
          [254, 251, 186],
          [253, 192, 114],
          [252, 172, 96],
          [253, 190, 112],
          [253, 222, 142],
          [252, 172, 96],
          [253, 196, 118],
          [253, 210, 131],
          [254, 234, 160],
          [254, 254, 190],
          [169, 216, 232],
          [253, 254, 194],
          [211, 236, 244],
          [251, 165, 92],
          [230, 245, 236],
          [49, 54, 149],
          [222, 63, 46],
          [253, 254, 194],
          [230, 245, 236],
          [57, 81, 162],
          [240, 101, 63],
          [250, 253, 198],
          [231, 245, 234],
          [88, 140, 191],
          [250, 157, 89],
          [249, 252, 201],
          [254, 254, 192],
          [225, 243, 245],
          [215, 238, 245],
          [254, 247, 179],
          [174, 218, 233],
          [180, 221, 235],
          [163, 210, 229],
          [192, 227, 239],
          [169, 216, 232],
          [165, 212, 230],
          [186, 224, 237],
          [205, 233, 242],
          [238, 248, 221],
          [253, 174, 97],
          [254, 239, 167],
          [227, 244, 241],
          [221, 241, 247],
          [221, 241, 247],
          [222, 63, 46],
          [49, 54, 149],
          [156, 205, 226],
          [217, 239, 246],
          [220, 58, 43],
          [55, 73, 158],
          [172, 217, 233],
          [215, 238, 245],
          [229, 77, 52],
          [66, 108, 175],
          [172, 217, 233],
          [253, 178, 101],
          [236, 247, 225],
          [234, 247, 227],
          [253, 174, 97],
          [230, 245, 236],
          [251, 253, 196],
          [213, 237, 245],
          [226, 243, 243],
          [241, 249, 216],
          [209, 235, 243],
          [205, 233, 242],
          [186, 224, 237],
          [150, 200, 224],
          [253, 208, 129],
          [253, 214, 134],
          [75, 124, 183],
          [253, 198, 120],
          [68, 115, 179],
          [253, 254, 194],
          [156, 205, 226],
          [49, 54, 149],
          [65, 105, 174],
          [254, 237, 164],
          [124, 179, 212],
          [52, 63, 153],
          [67, 110, 176],
          [253, 202, 123],
          [106, 162, 203],
          [55, 73, 158],
          [186, 20, 38],
          [231, 245, 234],
          [232, 246, 232],
          [252, 172, 96],
          [254, 247, 179],
          [254, 233, 158],
          [247, 251, 205],
          [254, 250, 184],
          [254, 241, 169],
          [231, 245, 234],
          [245, 251, 207],
          [219, 240, 246],
          [209, 235, 243],
          [254, 247, 179],
          [254, 247, 179],
          [62, 96, 169],
          [254, 224, 144],
          [51, 61, 152],
          [230, 245, 236],
          [217, 239, 246],
          [65, 105, 174],
          [49, 54, 149],
          [241, 249, 216],
          [178, 220, 235],
          [62, 96, 169],
          [51, 61, 152],
          [254, 244, 175],
          [148, 198, 223],
          [63, 100, 172],
          [212, 45, 38],
          [254, 242, 171],
          [254, 227, 149],
          [244, 251, 210],
          [253, 178, 101],
          [252, 170, 95],
          [253, 184, 107],
          [253, 198, 120],
          [249, 147, 84],
          [253, 174, 97],
          [253, 194, 116],
          [253, 200, 121],
          [254, 226, 147],
          [156, 205, 226],
          [231, 245, 234],
          [230, 245, 236],
          [253, 194, 116],
          [243, 250, 212],
          [57, 81, 162],
          [220, 58, 43],
          [254, 237, 164],
          [241, 249, 216],
          [49, 54, 149],
          [232, 85, 56],
          [254, 242, 171],
          [237, 248, 223],
          [58, 83, 163],
          [246, 126, 75],
          [254, 243, 173],
          [237, 248, 223],
          [224, 243, 247],
          [219, 240, 246],
          [254, 228, 151],
          [194, 228, 239],
          [205, 233, 242],
          [169, 216, 232],
          [201, 231, 241],
          [194, 228, 239],
          [180, 221, 235],
          [192, 227, 239],
          [194, 228, 239],
          [228, 244, 239],
          [252, 172, 96],
          [254, 239, 167],
          [186, 224, 237],
          [244, 251, 210],
          [192, 227, 239],
          [240, 101, 63],
          [55, 73, 158],
          [124, 179, 212],
          [178, 220, 235],
          [232, 85, 56],
          [49, 54, 149],
          [126, 181, 213],
          [167, 214, 231],
          [230, 80, 53],
          [52, 66, 155],
          [122, 178, 211],
          [247, 131, 77],
          [239, 249, 218],
          [239, 249, 218],
          [249, 149, 85],
          [243, 250, 212],
          [254, 247, 179],
          [226, 243, 243],
          [233, 246, 230],
          [253, 254, 194],
          [226, 243, 243],
          [224, 243, 247],
          [188, 225, 238],
          [159, 207, 227],
          [253, 210, 131],
          [254, 230, 153],
          [69, 118, 180],
          [253, 200, 121],
          [69, 118, 180],
          [250, 253, 198],
          [172, 217, 233],
          [52, 63, 153],
          [62, 96, 169],
          [254, 242, 171],
          [126, 181, 213],
          [49, 54, 149],
          [60, 91, 167],
          [253, 206, 127],
          [103, 157, 201],
          [49, 56, 150],
          [184, 18, 38],
          [234, 247, 227],
          [239, 249, 218],
          [251, 159, 90],
          [254, 243, 173],
          [254, 228, 151],
          [245, 251, 207],
          [254, 250, 184],
          [254, 232, 156],
          [242, 250, 214],
          [248, 252, 203],
          [226, 243, 243],
          [221, 241, 247],
          [254, 242, 171],
          [251, 253, 196],
          [63, 100, 172],
          [253, 212, 132],
          [58, 83, 163],
          [231, 245, 234],
          [215, 238, 245],
          [67, 110, 176],
          [51, 61, 152],
          [237, 248, 223],
          [167, 214, 231],
          [60, 91, 167],
          [49, 54, 149],
          [254, 248, 180],
          [135, 188, 217],
          [59, 86, 164],
          [210, 43, 38],
          [254, 245, 177],
          [254, 230, 153],
          [227, 244, 241],
          [253, 180, 103],
          [253, 184, 107],
          [253, 180, 103],
          [253, 192, 114],
          [251, 162, 91],
          [253, 174, 97],
          [253, 186, 108],
          [253, 174, 97],
          [253, 198, 120],
          [154, 204, 225],
          [209, 235, 243],
          [254, 249, 182],
          [254, 236, 162],
          [254, 244, 175],
          [88, 140, 191],
          [229, 77, 52],
          [253, 202, 123],
          [254, 244, 175],
          [58, 83, 163],
          [230, 80, 53],
          [253, 206, 127],
          [254, 248, 180],
          [49, 54, 149],
          [238, 97, 61],
          [253, 208, 129],
          [194, 228, 239],
          [226, 243, 243],
          [226, 243, 243],
          [253, 216, 136],
          [205, 233, 242],
          [221, 241, 247],
          [176, 219, 234],
          [209, 235, 243],
          [213, 237, 245],
          [194, 228, 239],
          [194, 228, 239],
          [192, 227, 239],
          [221, 241, 247],
          [253, 174, 97],
          [254, 239, 167],
          [152, 202, 225],
          [254, 245, 177],
          [165, 212, 230],
          [250, 157, 89],
          [66, 108, 175],
          [106, 162, 203],
          [148, 198, 223],
          [246, 126, 75],
          [52, 66, 155],
          [103, 157, 201],
          [135, 188, 217],
          [238, 97, 61],
          [49, 54, 149],
          [95, 148, 196],
          [241, 104, 64],
          [243, 250, 212],
          [244, 251, 210],
          [250, 152, 86],
          [241, 249, 216],
          [254, 249, 182],
          [219, 240, 246],
          [231, 245, 234],
          [253, 254, 194],
          [227, 244, 241],
          [221, 241, 247],
          [194, 228, 239],
          [172, 217, 233],
          [253, 198, 120],
          [254, 230, 153],
          [71, 120, 181],
          [253, 192, 114],
          [75, 124, 183],
          [249, 252, 201],
          [172, 217, 233],
          [55, 73, 158],
          [63, 100, 172],
          [254, 243, 173],
          [122, 178, 211],
          [49, 56, 150],
          [59, 86, 164],
          [253, 208, 129],
          [95, 148, 196],
          [49, 54, 149],
          [182, 16, 38],
          [254, 247, 179],
          [254, 243, 173],
          [169, 216, 232],
          [254, 227, 149],
          [254, 250, 184],
          [253, 210, 131],
          [253, 222, 142],
          [254, 238, 166],
          [253, 208, 129],
          [253, 200, 121],
          [253, 178, 101],
          [249, 147, 84],
          [190, 226, 238],
          [190, 226, 238],
          [222, 63, 46],
          [167, 214, 231],
          [221, 61, 45],
          [254, 254, 192],
          [253, 178, 101],
          [186, 20, 38],
          [212, 45, 38],
          [237, 248, 223],
          [247, 131, 77],
          [184, 18, 38],
          [210, 43, 38],
          [194, 228, 239],
          [241, 104, 64],
          [182, 16, 38],
          [49, 54, 149],
        ],
        [
          [184, 223, 236],
          [133, 186, 216],
          [234, 247, 227],
          [249, 149, 85],
          [253, 190, 112],
          [239, 99, 62],
          [251, 162, 91],
          [253, 206, 127],
          [230, 245, 236],
          [204, 37, 38],
          [232, 246, 232],
          [253, 186, 108],
          [88, 140, 191],
          [59, 88, 166],
          [248, 142, 82],
          [49, 54, 149],
          [253, 206, 127],
          [228, 75, 51],
          [176, 219, 234],
          [241, 104, 64],
          [253, 186, 108],
          [244, 109, 67],
          [224, 243, 247],
          [239, 99, 62],
          [252, 172, 96],
          [253, 202, 123],
          [254, 251, 186],
          [232, 85, 56],
          [118, 174, 209],
          [161, 209, 228],
          [144, 195, 221],
          [199, 230, 240],
          [253, 200, 121],
          [250, 253, 198],
          [253, 184, 107],
          [245, 116, 70],
          [233, 246, 230],
          [232, 246, 232],
          [252, 172, 96],
          [248, 252, 203],
          [253, 254, 194],
          [67, 113, 178],
          [165, 212, 230],
          [253, 208, 129],
          [178, 220, 235],
          [253, 200, 121],
          [254, 232, 156],
          [254, 241, 169],
          [237, 94, 60],
          [250, 157, 89],
          [253, 204, 125],
          [254, 224, 144],
          [226, 73, 50],
          [242, 106, 65],
          [253, 210, 131],
          [253, 202, 123],
          [208, 41, 38],
          [156, 205, 226],
          [186, 224, 237],
          [150, 200, 224],
          [207, 234, 243],
          [253, 186, 108],
          [254, 226, 147],
          [248, 139, 81],
          [252, 170, 95],
          [254, 232, 156],
          [237, 248, 223],
          [226, 73, 50],
          [249, 252, 201],
          [253, 176, 99],
          [92, 144, 194],
          [68, 115, 179],
          [248, 139, 81],
          [61, 93, 168],
          [253, 196, 118],
          [245, 121, 72],
          [209, 235, 243],
          [238, 97, 61],
          [253, 178, 101],
          [251, 165, 92],
          [241, 249, 216],
          [231, 82, 54],
          [252, 170, 95],
          [254, 236, 162],
          [254, 239, 167],
          [229, 77, 52],
          [106, 162, 203],
          [141, 193, 220],
          [110, 166, 205],
          [233, 246, 230],
          [252, 167, 94],
          [254, 241, 169],
          [253, 188, 110],
          [244, 109, 67],
          [254, 251, 186],
          [239, 249, 218],
          [250, 157, 89],
          [251, 253, 196],
          [253, 182, 105],
          [144, 195, 221],
          [118, 174, 209],
          [254, 224, 144],
          [99, 153, 198],
          [254, 244, 175],
          [249, 147, 84],
          [201, 231, 241],
          [250, 152, 86],
          [254, 236, 162],
          [251, 162, 91],
          [236, 247, 225],
          [248, 139, 81],
          [254, 227, 149],
          [253, 206, 127],
          [254, 247, 179],
          [247, 134, 78],
          [182, 222, 236],
          [126, 181, 213],
          [92, 144, 194],
          [224, 243, 247],
          [237, 94, 60],
          [231, 82, 54],
          [216, 51, 40],
          [250, 157, 89],
          [247, 131, 77],
          [228, 244, 239],
          [172, 7, 38],
          [165, 212, 230],
          [250, 154, 88],
          [97, 151, 197],
          [64, 103, 173],
          [250, 157, 89],
          [59, 88, 166],
          [254, 224, 144],
          [222, 63, 46],
          [112, 168, 206],
          [245, 121, 72],
          [253, 192, 114],
          [239, 99, 62],
          [169, 216, 232],
          [236, 92, 59],
          [253, 174, 97],
          [253, 192, 114],
          [227, 244, 241],
          [232, 85, 56],
          [128, 183, 214],
          [122, 178, 211],
          [80, 131, 187],
          [232, 246, 232],
          [248, 142, 82],
          [253, 212, 132],
          [242, 106, 65],
          [249, 147, 84],
          [254, 233, 158],
          [203, 232, 242],
          [208, 41, 38],
          [241, 249, 216],
          [253, 176, 99],
          [95, 148, 196],
          [67, 110, 176],
          [253, 210, 131],
          [49, 56, 150],
          [254, 238, 166],
          [231, 82, 54],
          [131, 185, 215],
          [250, 152, 86],
          [254, 228, 151],
          [244, 114, 69],
          [176, 219, 234],
          [248, 142, 82],
          [253, 220, 140],
          [253, 198, 120],
          [231, 245, 234],
          [247, 137, 79],
          [154, 204, 225],
          [192, 227, 239],
          [144, 195, 221],
          [232, 246, 232],
          [253, 178, 101],
          [253, 222, 142],
          [248, 139, 81],
          [252, 170, 95],
          [254, 225, 145],
          [237, 248, 223],
          [230, 80, 53],
          [251, 253, 196],
          [253, 182, 105],
          [110, 166, 205],
          [68, 115, 179],
          [251, 159, 90],
          [62, 96, 169],
          [253, 210, 131],
          [245, 116, 70],
          [213, 237, 245],
          [244, 109, 67],
          [253, 192, 114],
          [251, 159, 90],
          [243, 250, 212],
          [242, 106, 65],
          [253, 184, 107],
          [254, 232, 156],
          [254, 236, 162],
          [236, 92, 59],
          [116, 173, 209],
          [124, 179, 212],
          [103, 157, 201],
          [186, 224, 237],
          [253, 176, 99],
          [244, 251, 210],
          [253, 184, 107],
          [240, 101, 63],
          [244, 251, 210],
          [254, 254, 192],
          [244, 114, 69],
          [247, 137, 79],
          [249, 147, 84],
          [135, 188, 217],
          [77, 126, 185],
          [253, 202, 123],
          [62, 96, 169],
          [253, 214, 134],
          [252, 170, 95],
          [239, 249, 218],
          [226, 73, 50],
          [253, 214, 134],
          [253, 208, 129],
          [254, 244, 175],
          [237, 94, 60],
          [253, 210, 131],
          [244, 251, 210],
          [253, 212, 132],
          [240, 101, 63],
          [99, 153, 198],
          [159, 207, 227],
          [126, 181, 213],
          [184, 223, 236],
          [253, 222, 142],
          [225, 243, 245],
          [253, 214, 134],
          [251, 159, 90],
          [217, 239, 246],
          [254, 239, 167],
          [248, 142, 82],
          [246, 126, 75],
          [246, 129, 76],
          [178, 220, 235],
          [99, 153, 198],
          [253, 218, 138],
          [67, 110, 176],
          [253, 220, 140],
          [251, 159, 90],
          [254, 247, 179],
          [228, 75, 51],
          [254, 225, 145],
          [253, 194, 116],
          [253, 198, 120],
          [230, 80, 53],
          [253, 212, 132],
          [248, 252, 203],
          [251, 165, 92],
          [233, 87, 57],
          [86, 137, 190],
          [215, 238, 245],
          [188, 225, 238],
          [254, 254, 192],
          [253, 184, 107],
          [253, 210, 131],
          [253, 174, 97],
          [253, 192, 114],
          [253, 212, 132],
          [242, 250, 214],
          [249, 147, 84],
          [251, 253, 196],
          [254, 225, 145],
          [154, 204, 225],
          [101, 155, 199],
          [252, 172, 96],
          [97, 151, 197],
          [253, 186, 108],
          [253, 184, 107],
          [254, 253, 188],
          [245, 116, 70],
          [253, 182, 105],
          [253, 204, 125],
          [254, 241, 169],
          [246, 129, 76],
          [252, 170, 95],
          [254, 239, 167],
          [253, 222, 142],
          [242, 106, 65],
          [118, 174, 209],
          [241, 249, 216],
          [228, 244, 239],
          [233, 246, 230],
          [254, 228, 151],
          [254, 227, 149],
          [253, 190, 112],
          [254, 233, 158],
          [254, 244, 175],
          [226, 243, 243],
          [247, 137, 79],
          [245, 251, 207],
          [253, 206, 127],
          [176, 219, 234],
          [112, 168, 206],
          [239, 99, 62],
          [67, 110, 176],
          [250, 152, 86],
          [231, 82, 54],
          [228, 244, 239],
          [230, 80, 53],
          [249, 147, 84],
          [244, 111, 68],
          [254, 254, 192],
          [232, 85, 56],
          [248, 139, 81],
          [253, 214, 134],
          [253, 222, 142],
          [224, 68, 48],
          [104, 159, 202],
          [230, 245, 236],
          [219, 240, 246],
          [253, 216, 136],
          [253, 198, 120],
          [254, 234, 160],
          [254, 224, 144],
          [253, 184, 107],
          [254, 238, 166],
          [254, 238, 166],
          [253, 216, 136],
          [217, 239, 246],
          [238, 248, 221],
          [180, 221, 235],
          [205, 233, 242],
          [245, 251, 207],
          [184, 223, 236],
          [232, 246, 232],
          [254, 225, 145],
          [225, 243, 245],
          [254, 245, 177],
          [241, 249, 216],
          [253, 208, 129],
          [227, 244, 241],
          [254, 242, 171],
          [251, 253, 196],
          [253, 200, 121],
          [227, 244, 241],
          [254, 233, 158],
          [254, 254, 192],
          [167, 214, 231],
          [161, 209, 228],
          [174, 218, 233],
          [253, 192, 114],
          [254, 247, 179],
          [253, 194, 116],
          [245, 121, 72],
          [254, 248, 180],
          [254, 230, 153],
          [246, 126, 75],
          [250, 154, 88],
          [249, 147, 84],
          [99, 153, 198],
          [92, 144, 194],
          [253, 186, 108],
          [92, 144, 194],
          [253, 192, 114],
          [254, 232, 156],
          [254, 225, 145],
          [223, 65, 47],
          [253, 188, 110],
          [254, 249, 182],
          [253, 196, 118],
          [226, 73, 50],
          [253, 176, 99],
          [219, 240, 246],
          [253, 176, 99],
          [228, 75, 51],
          [82, 133, 188],
          [233, 246, 230],
          [221, 241, 247],
          [253, 218, 138],
          [254, 225, 145],
          [254, 250, 184],
          [254, 236, 162],
          [253, 204, 125],
          [254, 253, 188],
          [254, 245, 177],
          [254, 232, 156],
          [224, 243, 247],
          [239, 249, 218],
          [199, 230, 240],
          [225, 243, 245],
          [234, 247, 227],
          [192, 227, 239],
          [226, 243, 243],
          [253, 222, 142],
          [205, 233, 242],
          [247, 251, 205],
          [233, 246, 230],
          [253, 208, 129],
          [209, 235, 243],
          [251, 253, 196],
          [242, 250, 214],
          [253, 202, 123],
          [217, 239, 246],
          [254, 248, 180],
          [254, 242, 171],
          [190, 226, 238],
          [146, 197, 222],
          [247, 251, 205],
          [253, 180, 103],
          [254, 250, 184],
          [253, 188, 110],
          [248, 139, 81],
          [254, 251, 186],
          [254, 237, 164],
          [247, 131, 77],
          [254, 227, 149],
          [253, 200, 121],
          [182, 222, 236],
          [118, 174, 209],
          [253, 222, 142],
          [86, 137, 190],
          [254, 233, 158],
          [246, 124, 74],
          [230, 245, 236],
          [247, 137, 79],
          [254, 232, 156],
          [247, 137, 79],
          [253, 254, 194],
          [249, 149, 85],
          [254, 227, 149],
          [253, 208, 129],
          [254, 232, 156],
          [248, 144, 83],
          [152, 202, 225],
          [161, 209, 228],
          [131, 185, 215],
          [242, 250, 214],
          [250, 157, 89],
          [253, 184, 107],
          [244, 111, 68],
          [253, 174, 97],
          [253, 208, 129],
          [225, 243, 245],
          [225, 70, 49],
          [248, 252, 203],
          [253, 188, 110],
          [116, 173, 209],
          [77, 126, 185],
          [252, 167, 94],
          [67, 110, 176],
          [253, 200, 121],
          [251, 162, 91],
          [232, 246, 232],
          [241, 104, 64],
          [253, 190, 112],
          [253, 190, 112],
          [251, 253, 196],
          [244, 111, 68],
          [253, 184, 107],
          [254, 241, 169],
          [254, 233, 158],
          [241, 104, 64],
          [110, 166, 205],
          [205, 233, 242],
          [167, 214, 231],
          [228, 244, 239],
          [253, 192, 114],
          [253, 210, 131],
          [248, 142, 82],
          [253, 194, 116],
          [254, 227, 149],
          [233, 246, 230],
          [230, 80, 53],
          [254, 254, 190],
          [253, 174, 97],
          [122, 178, 211],
          [80, 131, 187],
          [246, 129, 76],
          [64, 103, 173],
          [253, 180, 103],
          [247, 131, 77],
          [232, 246, 232],
          [232, 85, 56],
          [251, 165, 92],
          [253, 178, 101],
          [254, 253, 188],
          [233, 87, 57],
          [250, 152, 86],
          [254, 242, 171],
          [253, 222, 142],
          [228, 75, 51],
          [99, 153, 198],
          [176, 219, 234],
          [167, 214, 231],
          [188, 225, 238],
          [253, 206, 127],
          [254, 224, 144],
          [252, 170, 95],
          [253, 206, 127],
          [254, 242, 171],
          [230, 245, 236],
          [249, 149, 85],
          [254, 254, 190],
          [252, 167, 94],
          [176, 219, 234],
          [144, 195, 221],
          [253, 208, 129],
          [112, 168, 206],
          [254, 227, 149],
          [252, 170, 95],
          [226, 243, 243],
          [250, 152, 86],
          [254, 227, 149],
          [253, 192, 114],
          [248, 252, 203],
          [247, 134, 78],
          [254, 225, 145],
          [254, 241, 169],
          [254, 237, 164],
          [247, 137, 79],
          [150, 200, 224],
          [169, 216, 232],
          [148, 198, 223],
          [192, 227, 239],
          [253, 208, 129],
          [241, 249, 216],
          [253, 208, 129],
          [249, 149, 85],
          [236, 247, 225],
          [254, 241, 169],
          [251, 159, 90],
          [253, 192, 114],
          [250, 157, 89],
          [167, 214, 231],
          [122, 178, 211],
          [253, 208, 129],
          [101, 155, 199],
          [253, 220, 140],
          [253, 184, 107],
          [254, 233, 158],
          [231, 82, 54],
          [253, 220, 140],
          [253, 198, 120],
          [253, 206, 127],
          [229, 77, 52],
          [253, 208, 129],
          [254, 250, 184],
          [253, 194, 116],
          [233, 87, 57],
          [104, 159, 202],
        ],

        [
          [49, 54, 149],
          [101, 155, 199],
          [66, 108, 175],
          [112, 168, 206],
          [133, 186, 216],
          [97, 151, 197],
          [77, 126, 185],
          [110, 166, 205],
          [101, 155, 199],
          [114, 170, 207],
          [110, 166, 205],
          [126, 181, 213],
          [103, 157, 201],
          [124, 179, 212],
          [112, 168, 206],
          [108, 164, 204],
          [104, 159, 202],
          [118, 174, 209],
          [71, 120, 181],
          [101, 155, 199],
          [49, 54, 149],
          [106, 162, 203],
          [135, 188, 217],
          [139, 191, 219],
          [118, 174, 209],
          [108, 164, 204],
          [133, 186, 216],
          [133, 186, 216],
          [126, 181, 213],
          [128, 183, 214],
          [141, 193, 220],
          [120, 176, 210],
          [135, 188, 217],
          [146, 197, 222],
          [124, 179, 212],
          [124, 179, 212],
          [144, 195, 221],
          [104, 159, 202],
          [66, 108, 175],
          [106, 162, 203],
          [49, 54, 149],
          [116, 173, 209],
          [137, 190, 218],
          [99, 153, 198],
          [68, 115, 179],
          [110, 166, 205],
          [97, 151, 197],
          [108, 164, 204],
          [106, 162, 203],
          [137, 190, 218],
          [97, 151, 197],
          [133, 186, 216],
          [116, 173, 209],
          [103, 157, 201],
          [101, 155, 199],
          [116, 173, 209],
          [69, 118, 180],
          [112, 168, 206],
          [135, 188, 217],
          [116, 173, 209],
          [49, 54, 149],
          [95, 148, 196],
          [110, 166, 205],
          [118, 174, 209],
          [137, 190, 218],
          [131, 185, 215],
          [141, 193, 220],
          [137, 190, 218],
          [144, 195, 221],
          [133, 186, 216],
          [131, 185, 215],
          [152, 202, 225],
          [137, 190, 218],
          [131, 185, 215],
          [139, 191, 219],
          [122, 178, 211],
          [133, 186, 216],
          [139, 191, 219],
          [137, 190, 218],
          [95, 148, 196],
          [49, 54, 149],
          [133, 186, 216],
          [150, 200, 224],
          [156, 205, 226],
          [133, 186, 216],
          [169, 216, 232],
          [156, 205, 226],
          [139, 191, 219],
          [150, 200, 224],
          [139, 191, 219],
          [154, 204, 225],
          [167, 214, 231],
          [156, 205, 226],
          [152, 202, 225],
          [118, 174, 209],
          [97, 151, 197],
          [118, 174, 209],
          [99, 153, 198],
          [110, 166, 205],
          [133, 186, 216],
          [49, 54, 149],
          [108, 164, 204],
          [120, 176, 210],
          [101, 155, 199],
          [137, 190, 218],
          [126, 181, 213],
          [159, 207, 227],
          [114, 170, 207],
          [152, 202, 225],
          [137, 190, 218],
          [128, 183, 214],
          [126, 181, 213],
          [141, 193, 220],
          [99, 153, 198],
          [77, 126, 185],
          [108, 164, 204],
          [68, 115, 179],
          [118, 174, 209],
          [150, 200, 224],
          [108, 164, 204],
          [49, 54, 149],
          [106, 162, 203],
          [95, 148, 196],
          [99, 153, 198],
          [99, 153, 198],
          [137, 190, 218],
          [92, 144, 194],
          [135, 188, 217],
          [112, 168, 206],
          [104, 159, 202],
          [92, 144, 194],
          [116, 173, 209],
          [69, 118, 180],
          [110, 166, 205],
          [133, 186, 216],
          [110, 166, 205],
          [137, 190, 218],
          [156, 205, 226],
          [120, 176, 210],
          [106, 162, 203],
          [49, 54, 149],
          [84, 135, 189],
          [128, 183, 214],
          [124, 179, 212],
          [156, 205, 226],
          [110, 166, 205],
          [150, 200, 224],
          [146, 197, 222],
          [124, 179, 212],
          [122, 178, 211],
          [144, 195, 221],
          [106, 162, 203],
          [101, 155, 199],
          [133, 186, 216],
          [97, 151, 197],
          [131, 185, 215],
          [133, 186, 216],
          [101, 155, 199],
          [95, 148, 196],
          [84, 135, 189],
          [49, 54, 149],
          [106, 162, 203],
          [106, 162, 203],
          [139, 191, 219],
          [97, 151, 197],
          [133, 186, 216],
          [144, 195, 221],
          [104, 159, 202],
          [106, 162, 203],
          [133, 186, 216],
          [99, 153, 198],
          [114, 170, 207],
          [126, 181, 213],
          [108, 164, 204],
          [141, 193, 220],
          [169, 216, 232],
          [137, 190, 218],
          [99, 153, 198],
          [128, 183, 214],
          [106, 162, 203],
          [49, 54, 149],
          [84, 135, 189],
          [161, 209, 228],
          [103, 157, 201],
          [156, 205, 226],
          [137, 190, 218],
          [110, 166, 205],
          [99, 153, 198],
          [141, 193, 220],
          [99, 153, 198],
          [110, 166, 205],
          [128, 183, 214],
          [106, 162, 203],
          [137, 190, 218],
          [156, 205, 226],
          [126, 181, 213],
          [99, 153, 198],
          [124, 179, 212],
          [106, 162, 203],
          [84, 135, 189],
          [49, 54, 149],
          [156, 205, 226],
          [112, 168, 206],
          [154, 204, 225],
          [137, 190, 218],
          [112, 168, 206],
          [97, 151, 197],
          [139, 191, 219],
          [97, 151, 197],
          [126, 181, 213],
          [141, 193, 220],
          [137, 190, 218],
          [144, 195, 221],
          [139, 191, 219],
          [159, 207, 227],
          [137, 190, 218],
          [156, 205, 226],
          [139, 191, 219],
          [161, 209, 228],
          [156, 205, 226],
          [49, 54, 149],
          [154, 204, 225],
          [77, 126, 185],
          [156, 205, 226],
          [159, 207, 227],
          [146, 197, 222],
          [167, 214, 231],
          [124, 179, 212],
          [103, 157, 201],
          [120, 176, 210],
          [97, 151, 197],
          [133, 186, 216],
          [150, 200, 224],
          [114, 170, 207],
          [92, 144, 194],
          [110, 166, 205],
          [97, 151, 197],
          [103, 157, 201],
          [112, 168, 206],
          [154, 204, 225],
          [49, 54, 149],
          [144, 195, 221],
          [139, 191, 219],
          [106, 162, 203],
          [101, 155, 199],
          [135, 188, 217],
          [97, 151, 197],
          [124, 179, 212],
          [135, 188, 217],
          [133, 186, 216],
          [131, 185, 215],
          [139, 191, 219],
          [152, 202, 225],
          [135, 188, 217],
          [150, 200, 224],
          [133, 186, 216],
          [156, 205, 226],
          [154, 204, 225],
          [77, 126, 185],
          [144, 195, 221],
          [49, 54, 149],
          [150, 200, 224],
          [152, 202, 225],
          [146, 197, 222],
          [156, 205, 226],
          [118, 174, 209],
          [112, 168, 206],
          [146, 197, 222],
          [116, 173, 209],
          [152, 202, 225],
          [154, 204, 225],
          [137, 190, 218],
          [112, 168, 206],
          [146, 197, 222],
          [144, 195, 221],
          [137, 190, 218],
          [137, 190, 218],
          [156, 205, 226],
          [139, 191, 219],
          [150, 200, 224],
          [49, 54, 149],
          [139, 191, 219],
          [135, 188, 217],
          [146, 197, 222],
          [120, 176, 210],
          [108, 164, 204],
          [124, 179, 212],
          [103, 157, 201],
          [137, 190, 218],
          [167, 214, 231],
          [128, 183, 214],
          [104, 159, 202],
          [124, 179, 212],
          [104, 159, 202],
          [110, 166, 205],
          [112, 168, 206],
          [159, 207, 227],
          [106, 162, 203],
          [152, 202, 225],
          [139, 191, 219],
          [49, 54, 149],
          [103, 157, 201],
          [141, 193, 220],
          [101, 155, 199],
          [104, 159, 202],
          [124, 179, 212],
          [101, 155, 199],
          [131, 185, 215],
          [156, 205, 226],
          [126, 181, 213],
          [92, 144, 194],
          [122, 178, 211],
          [106, 162, 203],
          [99, 153, 198],
          [97, 151, 197],
          [146, 197, 222],
          [101, 155, 199],
          [146, 197, 222],
          [135, 188, 217],
          [103, 157, 201],
          [49, 54, 149],
          [135, 188, 217],
          [95, 148, 196],
          [118, 174, 209],
          [144, 195, 221],
          [116, 173, 209],
          [139, 191, 219],
          [152, 202, 225],
          [141, 193, 220],
          [116, 173, 209],
          [144, 195, 221],
          [133, 186, 216],
          [141, 193, 220],
          [139, 191, 219],
          [167, 214, 231],
          [135, 188, 217],
          [156, 205, 226],
          [146, 197, 222],
          [141, 193, 220],
          [135, 188, 217],
          [49, 54, 149],
          [97, 151, 197],
          [71, 120, 181],
          [104, 159, 202],
          [69, 118, 180],
          [122, 178, 211],
          [118, 174, 209],
          [99, 153, 198],
          [69, 118, 180],
          [106, 162, 203],
          [99, 153, 198],
          [99, 153, 198],
          [97, 151, 197],
          [124, 179, 212],
          [97, 151, 197],
          [118, 174, 209],
          [120, 176, 210],
          [101, 155, 199],
          [95, 148, 196],
          [97, 151, 197],
          [49, 54, 149],
        ],
      ],
      formInline0: { region: '', product: '' },
      formInline: { region: '', product: '' },
      formInlines: { region: '', product: '' },
      program_name: [], //产品品类数组
      product_name: [],
      coordinate: [
        {
          value: '0',
          label: '感官属性',
        },
        {
          value: '1',
          label: '感官属性/消费者指标',
        },
        {
          value: '2',
          label: '消费者指标',
        },
      ], //选择坐标数组
      category: '',
      value: true,
      IMGlist: [], //获取到的图片数组
      indexing: 100, //图片下标
      show: 0,
      loading: false,
      sheetHeader: [],
      sheetData: [],
      taskID: '',
      downloadRole: false,
      showIMG: 0, //控制 显示隐藏按钮
      showTables: 0, //控制 表格 显示隐藏
      showChart: 1, //控制图表描述的显示和隐藏
      showShadow: 0, //控制阴影显示
      chnetworkImgUrl: [],
      ennetworkImgUrl: [],
    };
  },
  methods: {
    urlIndexChange(v) {
      console.log(v);
      this.urlIndex = v;
    },
    // 点击重置重置坐标
    ffChange() {
      if (this.show == 1) {
        this.ffindex = 0;
      }
    },
    // 点击热力图和网路图
    imgChange(val) {
      this.imgInfo = val;
    },
    terChange(v, val) {
      clearTimeout(time);
      time = setTimeout(() => {
        console.log(v, val, '1');
        if (this.indexing == 0) {
          this.num = v * 29 + val;
        } else if (this.indexing == 1) {
          this.num = v * 29 + val;
        } else if (this.indexing == 2) {
          this.num = v * 19 + val;
        }
        console.log(this.num);
      }, 250);
    },
    outChange() {
      clearTimeout(time);
      this.num = null;
      console.log('鼠标移出');
    },
    // NOTE下载图表
    async downloadIMG() {
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
        });
        return;
      }
      this.downloadRole = true;
      this.loading = true;
      let location;
      if (!this.imgInfo) {
        // ! 网络图传参
        location = this.ffindex;
      } else {
        // ! 热力图传参
        // if(this.$store.state.lang == "cn") location = this.formInline.product ;
        // else location = (this.formInline.product  - 0) +2
        location = this.formInline.product;
      }
      let response = await HttpClient.post(
        'CatAna_task/importance_download',
        {
          // type: "importance",
          taskid: this.taskID,
          location,
          type: this.imgInfo ? '1' : '2',
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let importancehref = window.URL.createObjectURL(blob);
      downloadElement.href = importancehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(importancehref);
      this.loading = false;
      this.downloadRole = false;
    },
    // NOTE下载图表
    async downloadIMGALL() {
      console.log('all');
      if (this.taskID == '') {
        this.$message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.analyse`),
          type: 'warning',
        });
        return;
      }
      this.downloadRole = true;
      this.loading = true;
      let response = await HttpClient.post(
        'CatAna_task/importance_downloadAll',
        {
          // type: "importance",
          taskid: this.taskID,
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      const blob = new Blob([response.data], {
        type: 'application/zip',
      });
      let name = getFilename(response);
      let downloadElement = document.createElement('a');
      let importancehref = window.URL.createObjectURL(blob);
      downloadElement.href = importancehref;
      downloadElement.download = `${name}`;
      // console.log("blob :>> ", blob);
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(importancehref);
      this.loading = false;
      this.downloadRole = false;
    },
    // 请求用户的产品品类
    async req_category() {
      let res = await HttpClient.post('subuser/getUesrProduct', {
        _id: this.$store.state.userid,
      });
      console.log(res);
      this.program_name = res.classification;
      if (this.$store.state.lang == 'cn') {
        console.log('this.program_name CN:>> ', this.program_name);
        this.program_name.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case 'Sun care':
              this.$set(this.program_name, index, '防晒霜');
              break;
            case 'Toner':
              this.$set(this.program_name, index, ' 爽肤水');
              break;
            case ' Lotion':
              this.$set(this.program_name, index, '乳液');
              break;
            case 'Hand lotion':
              this.$set(this.program_name, index, '护手霜');
              break;
            default:
              break;
          }
        });
      } else {
        console.log('this.program_name EN :>> ', this.program_name);
        this.program_name.forEach((item, index) => {
          console.log(item);
          switch (item) {
            case '防晒霜':
              // item = 'Sun care';
              this.$set(this.program_name, index, 'Sun care');
              break;
            case '爽肤水':
              item = 'Toner';
              this.$set(this.program_name, index, 'Toner');
              break;
            case '乳液':
              item = 'Lotion';
              this.$set(this.program_name, index, 'Lotion');
              break;
            case '护手霜':
              item = 'Hand lotion';
              this.$set(this.program_name, index, 'Hand lotion');
              break;
            default:
              break;
          }
        });
      }
      console.log(this.program_name);
    },

    // 选择品类
    changeName(e) {
      console.log(e);

      // this.category = this.program_name[e];

      if (this.program_name[e] == 'Sun care') {
        this.category = '防晒霜';
      } else {
        this.category = this.program_name[e];
      }
    },

    // 选择坐标
    changeCoordinate(e) {
      console.log(e);
      this.indexing = e;

      // this.headerData1=headerData1
      // this.data1=data1
      // this.headerData2=headerData2
      // this.data2=data2
      // this.headerData3=headerData3
      // this.data3=data3
      // console.log("data1 :>> ", data1);
      // console.log("headerData1 :>> ", headerData1);
      // console.log("data2 :>> ", data2);
      // console.log("headerDate2 :>> ", headerDate2);
      // console.log("data3 :>> ", data3);
      // console.log("headerDate3 :>> ", headerDate3);
      // if (e == 0) {
      //   if (this.$store.state.lang == 'cn') {
      //     this.sheetHeader = headerData2;
      //     this.sheetData = data2;
      //   } else {
      //     this.sheetHeader = enheaderData2;
      //     this.sheetData = endata2;
      //     this.headerData2 = enheaderData2;
      //     this.data2 = endata2;
      //   }
      // } else if (e == 1) {
      //   console.log('进入到1里面了');
      //   if (this.$store.state.lang == 'cn') {
      //     console.log('中文');
      //     this.sheetHeader = headerData1;
      //     this.sheetData = data1;
      //   } else {
      //     console.log('英文');
      //     this.sheetHeader = enheaderData1;
      //     this.sheetData = endata1;
      //     this.headerData1 = enheaderData1;
      //     this.data1 = endata1;
      //   }
      // } else if (e == 2) {
      //   if (this.$store.state.lang == 'cn') {
      //     this.sheetHeader = headerData3;
      //     this.sheetData = data3;
      //   } else {
      //     this.sheetHeader = enheaderData3;
      //     this.sheetData = endata3;
      //     this.headerData3 = enheaderData3;
      //     this.data3 = endata3;
      //   }
      // }
    },

    // 分析数据
    async Analyse() {
      let res = await HttpClient.post('CatAna_task/importance', {
        _id: this.$store.state.userid,
        category: this.category,
        language: this.$store.state.lang,
      });
      console.log(res, 'res');
      this.loading = false;
      this.IMGlist = res.result.importance;
      this.taskID = res.result._id;
      this.networkImgUrl = res.result.network;
      for (let i = 0; i < this.networkImgUrl.length; i++) {
        console.log(this.networkImgUrl[i]);
        if (i % 2 !== 0) {
          this.ennetworkImgUrl.push(this.networkImgUrl[i]);
        } else {
          this.chnetworkImgUrl.push(this.networkImgUrl[i]);
        }
      }
      console.log(this.IMGlist);
      this.showTables = 1;
      this.ffindex = 0;
    },

    // 开始分析
    ActionAnalyse() {
      if (this.category == '' || this.indexing == 100) {
        this.$message({
          showClose: true,
          message: this.$t(`message.Message.selectCP`),
          type: 'warning',
        });
      } else {
        this.loading = true;
        this.Analyse();
      }
    },
  },
  watch: {
    urlIndex() {
      console.log(this.urlIndex);
    },
    imgInfo() {
      console.log(this.imgInfo);
    },
    IMGlist() {
      if (this.IMGlist.length > 0) {
        this.$set(this.srcList, 0, this.IMGlist[this.indexing]);

        this.show = 1;
      }
    },
    indexing() {
      console.log('123');
      this.$set(this.srcList, 0, this.IMGlist[this.indexing]);
      // console.log(this.rgbarr, 'indexing');
    },
    '$store.state.lang': {
      handler() {
        console.log('触发了中英文切换');
        if (this.$store.state.lang === 'en') {
          this.coordinate = [
            {
              value: '0',
              label: 'Sensory Attributes',
            },
            {
              value: '1',
              label: 'Sensory/Consumer Attributes',
            },
            {
              value: '2',
              label: 'Consumer Attributes',
            },
          ];
          if (this.program_name[0] === '防晒霜') {
            this.$set(this.program_name, 0, 'Sun care');
          }
        } else {
          this.coordinate = [
            {
              value: '0',
              label: '感官属性',
            },
            {
              value: '1',
              label: '感官属性/消费者指标',
            },
            {
              value: '2',
              label: '消费者指标',
            },
          ];
          if (this.program_name[0] === 'Sun care') {
            this.$set(this.program_name, 0, '防晒霜');
          }
        }
      },
    },
    imgInfo: {
      handler() {
        if (this.imgInfo) {
          this.ffindex = 0;
        }
      },
    },
    'formInline.product': {
      handler() {
        console.log('监听到切换');
        this.imgInfo = true;
      },
      deep: true,
    },
  },
  created() {
    this.req_category();
  },
  mounted() {
    if (this.$store.state.lang == 'en') {
      this.coordinate = [
        {
          value: '0',
          label: 'Sensory Attributes',
        },
        {
          value: '1',
          label: 'Sensory/Consumer Attributes',
        },
        {
          value: '2',
          label: 'Consumer Attributes',
        },
      ];
    }
    document.addEventListener('click', (e) => {
      if (this.downloadShow == true) {
        if (e.target.id != 'downloadImg') {
          this.downloadShow = false;
        }
      }
    });
    console.log('产品轮廓');
    let Numdata = [];
    for (let item of this.sheetData) {
      let data = {};
      for (let key in item) {
        if (key == 'num0') {
          data[key] = item[key];
        } else {
          data[key] = Number(item[key]);
        }
      }
      Numdata.push(data);
    }
    // console.log(Numdata);
  },
};
</script>

<style lang="less" scoped>
@import url('../../style/Acategory/importanceAnalysis.less');
</style>
